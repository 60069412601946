import React from "react";
import About from "./About";
import Counter from "./Counter";
import Features from "./Features";
import Value from "./Value";
import Services from "./Services";
import Price from "./Price";
import Testimonials from "./Testimonials";
import Blogs from "./Blogs";
import Faq from "./Faq";
import Contact from "./Contact";

function Main() {
  return (
    <main id="main">
      <About />
      <Value />
      <Counter />
      <Features />
      <Services />
      <Price />
      <Testimonials />
      <Blogs />
      <Faq />
      <Contact />
    </main>
  );
}

export default Main;
