import React from "react";
import _ from "lodash";

function Pagination({ itemCount, pageSize, currentPage, onPageChange }) {
  const pageCount = Math.ceil(itemCount / pageSize);
  if (pageCount <= 1) return null;
  const pages = _.range(1, pageCount + 1);

  return (
    <nav aria-label="blog-pagination" className="blog-pagination">
      <ul className="pagination justify-content-center">
        <li
          className={`page-item ${currentPage === 1 ? "disabled" : undefined}`}
        >
          <a
            className="page-link"
            aria-label="Previous"
            onClick={() => onPageChange(currentPage - 1)}
          >
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {pages.map((page) => (
          <li
            key={page}
            className={`page-item ${
              currentPage === page ? "active" : undefined
            }`}
          >
            <a className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </a>
          </li>
        ))}

        <li
          className={`page-item ${
            currentPage === pageCount ? "disabled" : undefined
          }`}
        >
          <a
            className="page-link"
            aria-label="Next"
            onClick={() => onPageChange(currentPage + 1)}
          >
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
