import React from "react";

function HeroSection({ web, textChange, imageChange, imageSubmit }) {
  return (
    <div className="admin-form tab-pane fade show active" id="hero">
      <form>
        <h4 className="mb-3">Hero Section</h4>
        <div className="form-group">
          <input
            name="title"
            onChange={textChange}
            value={web.hero.title}
            type="text"
            className="form-control"
            placeholder="Hero Title"
          />
        </div>
        <div className="form-group">
          <input
            name="subtitle"
            onChange={textChange}
            value={web.hero.subtitle}
            type="text"
            className="form-control"
            placeholder="Hero Subtitle"
          />
        </div>
        <div className="form-group">
          <input
            name="video"
            onChange={textChange}
            value={web.hero.video}
            type="text"
            className="form-control"
            placeholder="Hero Video Link"
          />
        </div>
        <div className="form-group">
          <div className="input-group">
            <input
              className="form-control"
              type="file"
              id="formFile"
              name="image"
              onChange={imageChange}
            />
            <button
              className="btn btn-primary"
              type="submit"
              onClick={imageSubmit}
            >
              Upload
            </button>
          </div>
          <div className="progress">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              // style={{ width: `${blogImgPercentage}%` }}
            >
              {/* {blogImgPercentage}% */}
            </div>
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col-lg-6">
          <img className="img-fluid" src={web.hero.image} alt="" />
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
