import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../components/Breadcrumbs";
import { Link, useNavigate } from "react-router-dom";
import "../css/adminBlogsMgmt.css";
import { getBlogItems } from "../redux/actions/blogItemActions";
import { getCurrentUser } from "../redux/reducers/userReducers";
import Preloader from "../components/Preloader";
import { toast } from "react-toastify";

function AdminBlogsMgmt() {
  const user = getCurrentUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !user && navigate("/admin-entry");
  }, []);

  useEffect(() => {
    dispatch(getBlogItems());
  }, [dispatch]);

  const blogItems = useSelector((state) => state.getBlogItems);
  const { loading, items, error } = blogItems;

  useEffect(() => {
    setBlogs(items);
  }, [items]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <>
          <Helmet>
            <title>DStudio Tech. | Admin | Blogs Mgmt.</title>
          </Helmet>
          <main id="main">
            <Breadcrumbs title="Blogs Management" />
            <section id="blog-posts" className="blog-posts">
              <div className="container" data-aos="fade-up">
                <header className="section-header">
                  <h2>Blog</h2>
                  <div className="d-flex justify-content-center align-items-center new-blog">
                    <p>Your Blogs</p>
                    <Link to="/admin-blogs-mgmt/new">
                      <i className="bi bi-plus-circle"></i>
                    </Link>
                  </div>
                </header>
                {blogs && (
                  <div className="row">
                    {blogs.map((item) => (
                      <div className="col-lg-3 mb-4" key={item._id}>
                        <div className="post-box">
                          <div className="post-img">
                            <img
                              src={item.blogImage}
                              className="img-fluid"
                              alt="Blog Image"
                            />
                          </div>
                          <span className="post-date">
                            {item.insertDate.toString().substring(0, 10)}
                          </span>
                          <h3 className="post-title">
                            {item.blogTitle.toString().substring(0, 30)}...
                          </h3>
                          <Link
                            to={`/admin-blogs-mgmt/${item._id}`}
                            className="readmore stretched-link mt-auto"
                          >
                            <span>Review</span>
                            <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default AdminBlogsMgmt;
