import React from "react";
import "../css/showPwsIcon.css";

function ShowPwsIcon({ toggleShowPws, showPws }) {
  return (
    <i
      className={`bi bi-eye show-pws ${showPws ? "active" : undefined}`}
      onClick={toggleShowPws}
    ></i>
  );
}

export default ShowPwsIcon;
