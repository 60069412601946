import * as actionTypes from "../constants/itemConstants";
import axios from "axios";
import host from "../constants/hostConstant";
import { toast } from "react-toastify";

export const getTrainingItems = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_ITEMS_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.get(`${host}/api/training`);
    dispatch({
      type: actionTypes.GET_ITEMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ITEMS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const getTrainingItemDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_ITEM_DETAILS_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.get(`${host}/api/training/${id}`);
    dispatch({ type: actionTypes.GET_ITEM_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ITEM_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const postTrainingItem = (item) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.POST_ITEM_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.post(`${host}/api/training`, item);
    dispatch({ type: actionTypes.POST_ITEM_SUCCESS, payload: data });
    window.location.replace("/admin-training-mgmt");
    toast(`Your training item has been successfully posted`);
  } catch (error) {
    dispatch({
      type: actionTypes.POST_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const updateTrainingItem = (id, item) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.PUT_ITEM_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const body = { ...item };
    delete body._id;
    const { data } = await axios.put(`${host}/api/training/${id}`, body);
    dispatch({ type: actionTypes.POST_ITEM_SUCCESS, payload: data });
    window.location.replace("/admin-training-mgmt");
    toast(`Your training item has been successfully updated`);
  } catch (error) {
    dispatch({
      type: actionTypes.PUT_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const deleteTrainingItem = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETE_ITEM_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.delete(`${host}/api/training/${id}`);
    dispatch({ type: actionTypes.DELETE_ITEM_SUCCESS, payload: data });
    window.location.replace("/admin-training-mgmt");
    toast(`Your training item has been deleted`);
  } catch (error) {
    dispatch({
      type: actionTypes.DELETE_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};
