import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { EnvContext } from "../App";
import "../css/testimonials.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination } from "swiper";
// Import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Autoplay, Pagination]);

function Testimonials() {
  const { sectionRefs: refs } = useContext(EnvContext);
  const [data, setData] = useState({});

  const { loading, item } = useSelector((state) => state.getWebItemDetails);

  useEffect(() => {
    if (!loading) setData(item.testimonials);
  }, [loading, item]);

  return (
    <section id="testimonials" className="testimonials" ref={refs.testimonials}>
      {data && (
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>{data.subtitle}</h2>
            <p>{data.title}</p>
          </header>

          <div
            className="testimonials-slider swiper-container"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {data.data && (
              <Swiper
                speed={400}
                loop={true}
                slidesPerView={"auto"}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                pagination={{
                  el: ".swiper-pagination",
                  type: "bullets",
                  clickable: true,
                  bulletClass: "swiper-pagination-bullet",
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                }}
              >
                {data.data &&
                  data.data.map((item) => (
                    <SwiperSlide key={item._id}>
                      <div className="testimonial-item">
                        <div className="stars">
                          {_.range(1, item.rating + 1).map((star) => (
                            <i key={star} className="bi bi-star-fill"></i>
                          ))}
                        </div>
                        <p>{item.content}</p>
                        <div className="profile mt-auto">
                          <img
                            src={item.imgUrl}
                            className="testimonial-img"
                            alt=""
                          />
                          <h3>{item.username}</h3>
                          <h4>{item.occupation}</h4>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
            <div className="swiper-pagination"></div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Testimonials;
