import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import "../css/features.css";
import { EnvContext } from "../App";

function Features() {
  const { sectionRefs: refs } = useContext(EnvContext);
  const [data, setData] = useState({});

  const { loading, item } = useSelector((state) => state.getWebItemDetails);

  useEffect(() => {
    if (!loading) setData(item.features);
  }, [loading, item]);

  return (
    <section id="features" className="features" ref={refs.features}>
      {data && (
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>{data.subtitle}</h2>
            <p>{data.title}</p>
          </header>

          <div className="row">
            <div className="col-lg-6">
              <img
                src={data.partOne && data.partOne.image}
                className="img-fluid"
                alt=""
              />
            </div>

            <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div className="row align-self-center gy-4">
                {data.partOne &&
                  data.partOne.data.map((item) => (
                    <div
                      key={item._id}
                      className="col-md-6"
                      data-aos="zoom-out"
                      data-aos-delay={item.delay}
                    >
                      <div className="feature-box d-flex align-items-center">
                        <i className={item.icon}></i>
                        <h3>{item.name}</h3>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="row feture-tabs" data-aos="fade-up">
            <div className="col-lg-6">
              <h3>{data.partTwo && data.partTwo.title}</h3>

              <ul className="nav nav-pills mb-3">
                {data &&
                  data.partTwo &&
                  data.partTwo.tabs.map((item) => (
                    <li key={item._id}>
                      <a
                        className={`nav-link ${item.active && "active"}`}
                        data-bs-toggle="pill"
                        href={item.href}
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
              </ul>

              <div className="tab-content">
                {data &&
                  data.partTwo &&
                  data.partTwo.data.map((item) => (
                    <div
                      className={`tab-pane fade show ${
                        item.active && "active"
                      }`}
                      id={item.tabId}
                      key={item._id}
                    >
                      <p>{item.description}</p>
                      <div className="d-flex align-items-center mb-2">
                        <i className="bi bi-check2"></i>
                        <h4>{item.firstTitle}</h4>
                      </div>
                      <p>{item.partOne}</p>
                      <div className="d-flex align-items-center mb-2">
                        <i className="bi bi-check2"></i>
                        <h4>{item.secondTitle}</h4>
                      </div>
                      <p>{item.partTwo}</p>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col-lg-6">
              <img
                src={data.partTwo && data.partTwo.image}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>

          <div className="row feature-icons" data-aos="fade-up">
            <h3>{data.partThree && data.partThree.title}</h3>
            <div className="row">
              <div
                className="col-xl-4 text-center"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <img
                  src={data.partThree && data.partThree.image}
                  className="img-fluid p-4"
                  alt=""
                />
              </div>

              <div className="col-xl-8 d-flex content">
                <div className="row align-self-center gy-4">
                  {data &&
                    data.partThree &&
                    data.partThree.data.map((item) => (
                      <div
                        key={item._id}
                        className="col-md-6 icon-box"
                        data-aos="fade-up"
                        data-aos-delay={item.delay}
                      >
                        <i className={item.icon}></i>
                        <div>
                          <h4>{item.name}</h4>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Features;
