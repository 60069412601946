import React, { useState } from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";

function ContactForm() {
  const intialState = {
    name: "",
    email: "",
    subject: "",
    message: "",
    result: "",
  };
  const [text, setText] = useState(intialState);

  const changeText = (e) => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value, result: "" });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (text.fullname === "" || text.email === "" || text.message === "") {
      setText({ ...text, result: "incomplete" });
      toast("Please fill up all information.");
      return;
    }
    // show pending info while sending email
    setText({ ...text, result: "loading" });
    emailjs
      .sendForm(
        "service_ejq6ayi",
        "template_hn0h7ic",
        e.target,
        "NCmMADAilguXoO3n2"
      )
      .then(
        (result) => {
          setText({
            ...intialState,
            result: "success",
          });
          toast.success("Thank you! Your message has been sent.");
        },
        (error) => {
          setText({
            ...intialState,
            result: "error",
          });
          toast.error("Sorry...system error, message sent failed.");
        }
      );
  };

  return (
    <form onSubmit={sendEmail} className="php-email-form">
      <div className="row gy-4">
        <div className="col-md-6">
          <input
            type="text"
            name="name"
            value={text.name}
            onChange={changeText}
            className="form-control"
            placeholder="Your Name"
          />
        </div>

        <div className="col-md-6">
          <input
            type="email"
            className="form-control"
            name="email"
            value={text.email}
            onChange={changeText}
            placeholder="Your Email"
          />
        </div>

        <div className="col-md-12">
          <input
            type="text"
            className="form-control"
            name="subject"
            value={text.subject}
            onChange={changeText}
            placeholder="Subject"
          />
        </div>

        <div className="col-md-12">
          <textarea
            className="form-control"
            name="message"
            value={text.message}
            onChange={changeText}
            rows="6"
            placeholder="Message"
          ></textarea>
        </div>

        <div className="col-md-12 text-center">
          {text.result === "loading" && (
            <div className="loading">Sending message...</div>
          )}
          {text.result !== "loading" && (
            <button type="submit">Send Message</button>
          )}
        </div>
      </div>
    </form>
  );
}

export default ContactForm;
