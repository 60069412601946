import React, { useState, useEffect, useRef } from "react";
import { Provider } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "remixicon/fonts/remixicon.css";
import "boxicons/css/boxicons.min.css";
import "glightbox/dist/css/glightbox.css";
import "./App.css";

// customised components
import store from "./redux/store";
import TopBar from "./components/TopBar";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import PortfolioPage from "./pages/PortfolioPage";
import PortfolioDetailsPage from "./pages/PortfolioDetailsPage";
import BlogsPage from "./pages/BlogsPage";
import BlogSinglePage from "./pages/BlogSinglePage";
import QuotePage from "./pages/QuotePage";
import TrainingPage from "./pages/TrainingPage";
import TrainingSinglePage from "./pages/TrainingSinglePage";
import EmpolymentPage from "./pages/EmpolymentPage";
import NotFoundPage from "./pages/NotFoundPage";
import Footer from "./components/Footer";
import BackToTop from "./components/BackToTop";
import AdminEntryPage from "./pages/AdminEntryPage";
import AdminDashboard from "./pages/AdminDashboard";
import AdminBlogsMgmt from "./pages/AdminBlogsMgmt";
import AdminBlogsEdit from "./pages/AdminBlogsEdit";
import AdminPortfoliosMgmt from "./pages/AdminPortfoliosMgmt";
import AdminPortfoliosEdit from "./pages/AdminPortfoliosEdit";
import AdminContentMgmt from "./pages/AdminContentMgmt";
import AdminTrainingMgmt from "./pages/AdminTrainingMgmt";
import AdminTrainingEdit from "./pages/AdminTrainingEdit";
import AdminQueriesMgmt from "./pages/AdminQueriesMgmt";
import AdminNewsletterMgmt from "./pages/AdminNewsletterMgmt";
import AdminDataStatistic from "./pages/AdminDataStatistic";
import AdminUserProfile from "./pages/AdminUserProfile";

export const EnvContext = React.createContext();

function App() {
  const [scroll, setScroll] = useState(0);

  const headerRef = useRef(null);
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const valueRef = useRef(null);
  const counterRef = useRef(null);
  const featuresRef = useRef(null);
  const servicesRef = useRef(null);
  const priceRef = useRef(null);
  const portfolioRef = useRef(null);
  const blogsRef = useRef(null);
  const faqRef = useRef(null);
  const contactRef = useRef(null);
  const testimonialsRef = useRef(null);
  const footerRef = useRef(null);
  const preloaderRef = useRef(null);

  const sectionRefs = {
    header: headerRef,
    hero: heroRef,
    about: aboutRef,
    value: valueRef,
    counter: counterRef,
    features: featuresRef,
    services: servicesRef,
    price: priceRef,
    portfolio: portfolioRef,
    blogs: blogsRef,
    faq: faqRef,
    contact: contactRef,
    testimonials: testimonialsRef,
    footer: footerRef,
    preLoader: preloaderRef,
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY);
    });
    return () => {
      window.removeEventListener("scroll", () => {
        setScroll(window.scrollY);
      });
    };
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <>
      <Provider store={store}>
        <EnvContext.Provider value={{ scroll, sectionRefs }}>
          <ToastContainer />
          <TopBar />
          <Header />
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/portfolio" element={<PortfolioPage />} />
            <Route
              exact
              path="/portfolio/:id"
              element={<PortfolioDetailsPage />}
            />
            <Route exact path="/blogs" element={<BlogsPage />} />
            <Route exact path="/blogs/:id" element={<BlogSinglePage />} />
            <Route exact path="/training" element={<TrainingPage />} />
            <Route
              exact
              path="/training/:id"
              element={<TrainingSinglePage />}
            />
            <Route exact path="/employment" element={<EmpolymentPage />} />
            <Route exact path="/quotation" element={<QuotePage />} />
            <Route exact path="/admin-entry" element={<AdminEntryPage />} />
            <Route exact path="/admin-dashboard" element={<AdminDashboard />} />
            <Route
              exact
              path="/admin-blogs-mgmt"
              element={<AdminBlogsMgmt />}
            />
            <Route
              exact
              path="/admin-blogs-mgmt/:id"
              element={<AdminBlogsEdit />}
            />
            <Route
              exact
              path="/admin-portfolios-mgmt"
              element={<AdminPortfoliosMgmt />}
            />
            <Route
              exact
              path="/admin-portfolios-mgmt/:id"
              element={<AdminPortfoliosEdit />}
            />
            <Route
              exact
              path="/admin-training-mgmt"
              element={<AdminTrainingMgmt />}
            />
            <Route
              exact
              path="/admin-training-mgmt/:id"
              element={<AdminTrainingEdit />}
            />
            <Route
              exact
              path="/admin-content-mgmt"
              element={<AdminContentMgmt />}
            />
            <Route
              exact
              path="/admin-queries-mgmt"
              element={<AdminQueriesMgmt />}
            />
            <Route
              exact
              path="/admin-newsletter-mgmt"
              element={<AdminNewsletterMgmt />}
            />
            <Route
              exact
              path="/admin-training-mgmt"
              element={<AdminTrainingMgmt />}
            />
            <Route
              exact
              path="/admin-data-statistic"
              element={<AdminDataStatistic />}
            />
            <Route
              exact
              path="/admin-user-profile"
              element={<AdminUserProfile />}
            />
            <Route exact path="/not-found" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer />
          <BackToTop />
        </EnvContext.Provider>
      </Provider>
    </>
  );
}

export default App;
