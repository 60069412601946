import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolioItemDetails } from "../redux/actions/portfolioItemActions";
import Breadcrumbs from "../components/Breadcrumbs";
import Preloader from "../components/Preloader";
import "../css/portfolioDetailspage.css";

SwiperCore.use([Autoplay, Pagination]);

function PortfolioDetailsPage() {
  const dispatch = useDispatch();
  const { id: portfolioId } = useParams();
  const [portfolio, setPortfolio] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getPortfolioItemDetails(portfolioId));
  }, [dispatch]);

  const { loading, item, error } = useSelector(
    (state) => state.getPortfolioItemDetails
  );

  // set portfolio data
  useEffect(() => {
    if (!loading && !error) {
      setPortfolio(item);
    }
  }, [item, loading]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <>
          <Helmet>
            {portfolio.title && (
              <title>DStudio Tech. | Portfolio | {`${portfolio.title}`}</title>
            )}
            {portfolio.description && (
              <meta name="description" content={`${portfolio.description}`} />
            )}
          </Helmet>
          <main id="main">
            <Breadcrumbs title={`Portfolio Detail - ${portfolio.name}`} />
            <section id="portfolio-details" className="portfolio-details">
              <div className="container">
                <div className="row gy-4">
                  <div className="col-lg-8">
                    <div className="portfolio-details-slider swiper-container">
                      {portfolio.images && (
                        <Swiper
                          speed={600}
                          loop={true}
                          autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                          }}
                          slidesPerView={"auto"}
                          pagination={{
                            el: ".swiper-pagination",
                            type: "bullets",
                            clickable: true,
                          }}
                        >
                          {portfolio.images.map((item, index) => (
                            <SwiperSlide key={index}>
                              <img src={item.image} alt="Project Image" />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      )}

                      <div className="swiper-pagination"></div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="portfolio-info">
                      <h3>Project information</h3>
                      <ul>
                        <li>
                          <strong>Name</strong>: {portfolio.name}
                        </li>
                        <li>
                          <strong>Category</strong>: {portfolio.category}
                        </li>
                        <li>
                          <strong>Project date</strong>:{" "}
                          {portfolio.projectDate &&
                            portfolio.projectDate.substring(0, 10)}
                        </li>
                        <li>
                          <strong>Project Link</strong>:{" "}
                          <a target="_blank" href={portfolio.projectUrl}>
                            {portfolio.projectUrl}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="portfolio-description">
                      <h2>{portfolio.title}</h2>
                      <p>{portfolio.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default PortfolioDetailsPage;
