import React, { useState, useContext } from "react";
import { EnvContext } from "../App";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getCurrentUser } from "../redux/reducers/userReducers";
import { toast } from "react-toastify";
import "../css/navBar.css";

function NavBar() {
  const user = getCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [dropdownActive, setDropdowActive] = useState(false);
  const { scroll, sectionRefs: refs } = useContext(EnvContext);

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    toast("You have been logged out");
    navigate("/admin-entry");
  };

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const handleDropdowActive = () => {
    setDropdowActive(!dropdownActive);
  };

  const handleActiveNav = (scroll, currentSection, nextSection) => {
    if (refs.hero.current) {
      if (
        scroll > 0 &&
        scroll >=
          refs[currentSection].current.offsetTop -
            refs.header.current.offsetHeight &&
        scroll <
          refs[nextSection].current.offsetTop - refs.header.current.offsetHeight
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return;
    }
  };

  const pageScroll = async (section) => {
    await refs[section].current;
    if (refs[section].current) {
      if (scroll === 0) {
        window.scrollTo(0, refs[section].current.offsetTop + 1);
      } else {
        window.scrollTo(
          0,
          refs[section].current.offsetTop - refs.header.current.offsetHeight + 1
        );
      }
    } else {
      window.scrollTo(0, 0);
    }
  };

  const handlePageScroll = (section) => (e) => {
    e.preventDefault();
    if (refs[section].current && location.pathname === "/") {
      if (scroll === 0) {
        window.scrollTo(0, refs[section].current.offsetTop + 1);
      } else {
        window.scrollTo(
          0,
          refs[section].current.offsetTop - refs.header.current.offsetHeight + 1
        );
      }
    } else {
      navigate("/");
      pageScroll(section);
    }
  };

  return (
    <>
      {location.pathname.includes("admin") ? (
        <nav
          id="navbar"
          className={`navbar ${toggleMenu ? "navbar-mobile" : undefined}`}
        >
          <ul>
            <li>
              <Link
                className={`nav-link scrollto ${
                  location.pathname === "/admin-dashboard"
                    ? "active"
                    : undefined
                }`}
                to="/admin-dashboard"
                onClick={() => window.scrollTo(0, 0)}
              >
                <i className="bi bi-house-fill"></i>
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link scrollto ${
                  location.pathname.includes("/admin-blogs-mgmt")
                    ? "active"
                    : undefined
                }`}
                to="/admin-blogs-mgmt"
              >
                Blogs Mgmt.
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link scrollto ${
                  location.pathname === "/admin-portfolios-mgmt"
                    ? "active"
                    : undefined
                }`}
                to="/admin-portfolios-mgmt"
              >
                Portfolios Mgmt.
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link scrollto ${
                  location.pathname === "/admin-content-mgmt"
                    ? "active"
                    : undefined
                }`}
                to="/admin-content-mgmt"
              >
                Content Mgmt.
              </Link>
            </li>
            <li>
              <Link
                className={`nav-link scrollto ${
                  location.pathname === "/admin-training-mgmt"
                    ? "active"
                    : undefined
                }`}
                to="/admin-training-mgmt"
              >
                Training Mgmt.
              </Link>
            </li>
            <li className="dropdown">
              <Link
                onClick={handleDropdowActive}
                className={`nav-link scrollto ${
                  location.pathname === "/admin-user-profile" ||
                  location.pathname === "/admin-newsletter-mgmt" ||
                  location.pathname === "/admin-queries-mgmt"
                    ? "active"
                    : undefined
                }`}
              >
                <span>More</span>{" "}
                <i className="bi bi-chevron-down dropdown-indicator"></i>
              </Link>
              <ul
                className={
                  toggleMenu && dropdownActive ? "dropdown-active" : undefined
                }
              >
                <li>
                  <Link
                    className={`nav-link scrollto ${
                      location.pathname === "/admin-queries-mgmt"
                        ? "active"
                        : undefined
                    }`}
                    to="/admin-queries-mgmt"
                  >
                    Queries Mgmt.
                  </Link>
                </li>
                <li>
                  <Link
                    className={`nav-link scrollto ${
                      location.pathname === "/admin-newsletter-mgmt"
                        ? "active"
                        : undefined
                    }`}
                    to="/admin-newsletter-mgmt"
                  >
                    Newsletter Subsriptions
                  </Link>
                </li>
                <li>
                  <Link
                    className={`nav-link scrollto ${
                      location.pathname === "/admin-user-profile"
                        ? "active"
                        : undefined
                    }`}
                    to="/admin-user-profile"
                  >
                    Admin User Profile
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              {user ? (
                <Link
                  className="getstarted scrollto"
                  to="/admin-entry"
                  onClick={logout}
                >
                  Logout <i className="bi bi-box-arrow-in-right"></i>
                </Link>
              ) : (
                <Link className="getstarted scrollto" to="/">
                  Home <i className="bi bi-house-fill"></i>
                </Link>
              )}
            </li>
          </ul>
          <i
            className={`bi ${
              toggleMenu ? "bi-x" : "bi-list"
            } mobile-nav-toggle`}
            onClick={handleToggleMenu}
          ></i>
        </nav>
      ) : (
        <nav
          id="navbar"
          className={`navbar ${toggleMenu ? "navbar-mobile" : undefined}`}
        >
          <ul>
            <li>
              <Link
                className={`nav-link scrollto ${
                  location.pathname === "/" &&
                  (scroll === 0 || handleActiveNav(scroll, "header", "about"))
                    ? "active"
                    : undefined
                }`}
                to="/"
                onClick={() => window.scrollTo(0, 0)}
              >
                <i className="bi bi-house-fill"></i>
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className={`nav-link scrollto ${
                  handleActiveNav(scroll, "about", "services")
                    ? "active"
                    : undefined
                }`}
                onClick={handlePageScroll("about")}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className={`nav-link scrollto ${
                  handleActiveNav(scroll, "services", "price")
                    ? "active"
                    : undefined
                }`}
                onClick={handlePageScroll("services")}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className={`nav-link scrollto ${
                  handleActiveNav(scroll, "price", "testimonials")
                    ? "active"
                    : undefined
                }`}
                onClick={handlePageScroll("price")}
              >
                Price
              </Link>
            </li>
            <li>
              <Link
                to="/portfolio"
                className={`nav-link scrollto ${
                  location.pathname.includes("portfolio") ? "active" : undefined
                }`}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                to="/blogs"
                className={`nav-link scrollto ${
                  location.pathname.includes("blogs") ? "active" : undefined
                }`}
              >
                Blog
              </Link>
            </li>
            <li className="dropdown">
              <Link
                className={`nav-link ${
                  location.pathname === "/training" ||
                  location.pathname === "/employment"
                    ? "active"
                    : undefined
                }`}
                onClick={handleDropdowActive}
              >
                <span>More</span>{" "}
                <i className="bi bi-chevron-down dropdown-indicator"></i>
              </Link>
              <ul
                className={
                  toggleMenu && dropdownActive ? "dropdown-active" : undefined
                }
              >
                <li>
                  <Link
                    to="/training"
                    className={`nav-link scrollto ${
                      location.pathname === "/training" ? "active" : undefined
                    }`}
                  >
                    Training
                  </Link>
                </li>
                <li>
                  <Link
                    to="employment"
                    className={`nav-link scrollto ${
                      location.pathname === "/employment" ? "active" : undefined
                    }`}
                  >
                    Employment
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/"
                className={`nav-link scrollto ${
                  handleActiveNav(scroll, "contact", "footer")
                    ? "active"
                    : undefined
                }`}
                onClick={handlePageScroll("contact")}
              >
                Contact
              </Link>
            </li>
            <li>
              <a className="getstarted" href="/quotation">
                Get Quote
              </a>
            </li>
          </ul>
          <i
            className={`bi ${
              toggleMenu ? "bi-x" : "bi-list"
            } mobile-nav-toggle`}
            onClick={handleToggleMenu}
          ></i>
        </nav>
      )}
    </>
  );
}

export default NavBar;
