const adminDashboardData = [
  {
    _id: 1,
    title: `Blogs Mgmt.`,
    icon: "bi bi-pencil-square",
    description: `Review, edit, and create organization blogs`,
    link: `/admin-blogs-mgmt`,
  },
  {
    _id: 2,
    title: `Portfolios Mgmt.`,
    icon: "bi bi-journal-bookmark",
    description: `Review, edit, and create portfolio projects`,
    link: `/admin-portfolios-mgmt`,
  },
  {
    _id: 3,
    title: `Content Mgmt.`,
    icon: "bi bi-easel",
    description: `Review, edit, and create web content`,
    link: `/admin-content-mgmt`,
  },
  {
    _id: 4,
    title: `Training Mgmt.`,
    icon: "bi bi-chat-square-text",
    description: `Review, edit, and create training course items`,
    link: `/admin-training-mgmt`,
  },
  {
    _id: 5,
    title: `Queries Mgmt.`,
    icon: "bi bi-patch-question",
    description: `Review and edit clients quotation request`,
    link: `/admin-queries-mgmt`,
  },
  {
    _id: 6,
    title: `Newsletter Subsriptions`,
    icon: "bi bi-newspaper",
    description: `Review and edit newsletter subscribers`,
    link: `/admin-newsletter-mgmt`,
  },
];

export default adminDashboardData;
