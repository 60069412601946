import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import "../css/price.css";
import { EnvContext } from "../App";
import { Link } from "react-router-dom";

function Price() {
  const { sectionRefs: refs } = useContext(EnvContext);
  const [data, setData] = useState({});
  const { loading, item } = useSelector((state) => state.getWebItemDetails);

  useEffect(() => {
    if (!loading) setData(item.pricing);
  }, [loading, item]);

  return (
    <div>
      <section id="pricing" className="pricing" ref={refs.price}>
        {data && (
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>{data.subtitle}</h2>
              <p>{data.title}</p>
            </header>

            <div className="row gy-4" data-aos="fade-left">
              {data.data &&
                data.data.map((item) => (
                  <div
                    key={item._id}
                    className="col-lg-3 col-md-6"
                    data-aos="zoom-in"
                    data-aos-delay={item.delay}
                  >
                    <div className="box">
                      {item.featured && (
                        <span className="featured">Featured</span>
                      )}
                      <h3 style={{ color: `${item.color}` }}>{item.name}</h3>
                      <div className="price">
                        <span>from</span>
                        <sup>$</sup>
                        {item.price}
                      </div>
                      <img
                        src={item.imgUrl}
                        className="img-fluid"
                        alt={item.name}
                      />
                      <ul>
                        {item.list.map((line) => (
                          <li
                            key={line._id}
                            className={line.na ? "na" : undefined}
                          >
                            {line.name}
                          </li>
                        ))}
                      </ul>
                      <Link to="/quotation" className="btn-buy">
                        Explore
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

export default Price;
