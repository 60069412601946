import portfolioImg1 from "../assets/portfolio/portfolio-1.jpg";
import portfolioImg2 from "../assets/portfolio/portfolio-2.jpg";
import portfolioImg3 from "../assets/portfolio/portfolio-3.jpg";
import portfolioImg4 from "../assets/portfolio/portfolio-4.jpg";
import portfolioImg5 from "../assets/portfolio/portfolio-5.jpg";
import portfolioImg6 from "../assets/portfolio/portfolio-6.jpg";
import portfolioImg7 from "../assets/portfolio/portfolio-7.jpg";
import portfolioImg8 from "../assets/portfolio/portfolio-8.jpg";
import portfolioImg9 from "../assets/portfolio/portfolio-9.jpg";

const filterData = [
  {
    _id: 1,
    filter: "*",
    name: "All",
    active: true,
  },
  {
    _id: 2,
    filter: "filter-web",
    name: "Web",
    active: false,
  },
  {
    _id: 3,
    filter: "filter-uxui",
    name: "Design",
    active: false,
  },
  {
    _id: 4,
    filter: "filter-app",
    name: "App",
    active: false,
  },
  {
    _id: 5,
    filter: "filter-others",
    name: "Others",
    active: false,
  },
];

const portfolioData = [
  {
    _id: 1,
    filter: "filter-web",
    imgUrl: portfolioImg1,
    imgAlt: "",
    title: "Style 1",
    content: "Add Description to this style",
  },
  {
    _id: 2,
    filter: "filter-uxui",
    imgUrl: portfolioImg2,
    imgAlt: "",
    title: "Style 2",
    content: "Add Description to this style",
  },
  {
    _id: 3,
    filter: "filter-app",
    imgUrl: portfolioImg3,
    imgAlt: "",
    title: "Style 3",
    content: "Add Description to this style",
  },
  {
    _id: 4,
    filter: "filter-web",
    imgUrl: portfolioImg4,
    imgAlt: "",
    title: "Style 4",
    content: "Add Description to this style",
  },
  {
    _id: 5,
    filter: "filter-app",
    imgUrl: portfolioImg5,
    imgAlt: "",
    title: "Style 5",
    content: "Add Description to this style",
  },
  {
    _id: 6,
    filter: "filter-web",
    imgUrl: portfolioImg6,
    imgAlt: "",
    title: "Style 6",
    content: "Add Description to this style",
  },
  {
    _id: 7,
    filter: "filter-uxui",
    imgUrl: portfolioImg7,
    imgAlt: "",
    title: "Style 7",
    content: "Add Description to this style",
  },
  {
    _id: 8,
    filter: "filter-web",
    imgUrl: portfolioImg8,
    imgAlt: "",
    title: "Style 8",
    content: "Add Description to this style",
  },
  {
    _id: 9,
    filter: "filter-web",
    imgUrl: portfolioImg9,
    imgAlt: "",
    title: "Style 9",
    content: "Add Description to this style",
  },
];

export { portfolioData, filterData };
