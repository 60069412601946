import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../components/Breadcrumbs";
import blogAuthoerImg from "../assets/blogs/avatar.jpg";
import { getBlogItemDetails } from "../redux/actions/blogItemActions";
import Preloader from "../components/Preloader";
import NotFoundPage from "./NotFoundPage";
import BlogSideBar from "../components/BlogSideBar";

function BlogSinglePage() {
  const { id: blogId } = useParams();
  const dispatch = useDispatch();
  const [blog, setBlog] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getBlogItemDetails(blogId));
  }, [dispatch, blogId]);

  const { loading, item, error } = useSelector(
    (state) => state.getBlogItemDetails
  );

  useEffect(() => {
    setBlog(item);
  }, [item]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        <NotFoundPage />
      ) : (
        <>
          <Helmet>
            {blog.blogTitle && (
              <title>DStudio Tech. | Blog | {`${blog.blogTitle}`}</title>
            )}
            {blog.section && (
              <meta
                name="description"
                content={`${blog.section[0].paragraph.substring(0, 300)}`}
              />
            )}
          </Helmet>
          <main id="main">
            <Breadcrumbs title="Blog Article" />
            <section id="blog" className="blog">
              <div className="container" data-aos="fade-up">
                <div className="row">
                  <div className="col-lg-8 entries">
                    <article className="entry entry-single">
                      <div className="entry-img">
                        {blog.blogImage && (
                          <img
                            src={blog.blogImage}
                            alt="Blog Image"
                            className="img-fluid"
                          />
                        )}
                      </div>
                      {blog.blogTitle && (
                        <h2 className="entry-title">{blog.blogTitle}</h2>
                      )}
                      <div className="entry-meta">
                        <ul>
                          <li className="d-flex align-items-center">
                            <i className="bi bi-person"></i>{" "}
                            {blog.author && <span>{blog.author.name}</span>}
                          </li>
                          <li className="d-flex align-items-center">
                            <i className="bi bi-clock"></i>{" "}
                            <span>
                              {blog.insertDate && (
                                <time dateTime={blog.insertDate}>
                                  {blog.insertDate.toString().substring(0, 10)}
                                </time>
                              )}
                            </span>
                          </li>
                          <li className="d-flex align-items-center">
                            <i className="bi bi-eye"></i>{" "}
                            <span>
                              {blog.views && (
                                <Link to={`/blogs/${blog._id}`}>
                                  {blog.views} Views
                                </Link>
                              )}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div className="entry-content">
                        {blog.section &&
                          blog.section.map((part, index) => (
                            <div key={index}>
                              {part.title ? <h3>{part.title}</h3> : ""}
                              <p>{part.paragraph}</p>
                              {part.quote ? (
                                <blockquote>
                                  <p>{part.quote}</p>
                                </blockquote>
                              ) : (
                                ""
                              )}
                              {part.image ? (
                                <img
                                  src={part.image}
                                  className="img-fluid"
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          ))}
                      </div>

                      <div className="entry-footer">
                        <i className="bi bi-folder"></i>{" "}
                        <ul className="cats">
                          <li>
                            {blog.category && (
                              <Link to="/">{blog.category}</Link>
                            )}
                          </li>
                        </ul>
                        <i className="bi bi-tags"></i>{" "}
                        <ul className="tags">
                          {blog.tags &&
                            blog.tags.map((tag) => (
                              <li key={tag}>
                                <Link to="/">{tag}</Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </article>

                    <div className="blog-author d-flex align-items-center">
                      {blog.author && blog.author.image ? (
                        <img
                          src={blog.author.image}
                          className="rounded-circle float-left"
                          alt=""
                        />
                      ) : (
                        <img
                          src={blogAuthoerImg}
                          className="rounded-circle float-left"
                          alt=""
                        />
                      )}
                      <div>
                        {blog.author && <h4>{blog.author.name}</h4>}
                        <div className="social-links">
                          {blog.author &&
                            blog.author.social.map((item) => (
                              <a
                                key={item._id}
                                target="_blank"
                                href="https://twitters.com/#"
                              >
                                <i className={`bi bi-${item.icon}`}></i>
                              </a>
                            ))}
                        </div>
                        {blog.author && <p>{blog.author.description}</p>}
                      </div>
                    </div>
                  </div>
                  <BlogSideBar />
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default BlogSinglePage;
