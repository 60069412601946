import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import { getCurrentUser } from "../redux/reducers/userReducers";
import adminDashboardData from "../data/adminDashboardData";
import "../css/adminDashboard.css";

function AdminDashboard() {
  const user = getCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !user && navigate("/admin-entry");
  }, []);

  return (
    <>
      <Helmet>
        <title>DStudio Tech. | Admin Dashboard</title>
      </Helmet>
      <main id="main">
        <Breadcrumbs title="Admin Dashboard" />
        <section id="admin" className="admin sections-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Administration</h2>
              <p>User Dashboard</p>
            </div>

            <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
              {adminDashboardData.map((item) => (
                <div className="col-lg-4 col-md-6" key={item._id}>
                  <div className="admin-item position-relative">
                    <div className="icon">
                      <i className={item.icon}></i>
                    </div>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <Link to={item.link} className="readmore stretched-link">
                      Enter <i className="bi bi-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default AdminDashboard;
