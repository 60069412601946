import * as actionTypes from "../constants/itemConstants";
import axios from "axios";
import host from "../constants/hostConstant";
import { toast } from "react-toastify";

export const getNewsletterItems = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_ITEMS_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.get(`${host}/api/newsletters`);
    dispatch({
      type: actionTypes.GET_ITEMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ITEMS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const getNewsletterItemDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_ITEM_DETAILS_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.get(`${host}/api/newsletters/${id}`);
    dispatch({ type: actionTypes.GET_ITEM_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ITEM_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const postNewsletterItem = (item) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.POST_ITEM_REQUEST });
    const { data } = await axios.post(`${host}/api/newsletters`, item);
    dispatch({ type: actionTypes.POST_ITEM_SUCCESS, payload: data });
    toast.success(
      `You have successfully subscribed our newsletter, Thank You!`
    );
  } catch (error) {
    dispatch({
      type: actionTypes.POST_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const updateNewsletterItem = (id, item) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.PUT_ITEM_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const body = { ...item };
    delete body._id;
    const { data } = await axios.put(`${host}/api/newsletters/${id}`, body);
    dispatch({ type: actionTypes.POST_ITEM_SUCCESS, payload: data });
    toast.success(`Newsletter subscriber has been successfully updated`);
  } catch (error) {
    dispatch({
      type: actionTypes.PUT_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const deleteNewsletterItem = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETE_ITEM_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.delete(`${host}/api/newsletters/${id}`);
    dispatch({ type: actionTypes.DELETE_ITEM_SUCCESS, payload: data });
    toast.success(`This newsletter subscriber has been deleted`);
  } catch (error) {
    dispatch({
      type: actionTypes.DELETE_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};
