import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../css/services.css";
import { EnvContext } from "../App";

function Services() {
  const { sectionRefs: refs } = useContext(EnvContext);
  const [data, setData] = useState({});

  const { loading, item } = useSelector((state) => state.getWebItemDetails);

  useEffect(() => {
    if (!loading) setData(item.services);
  }, [loading, item]);

  return (
    <section id="services" className="services" ref={refs.services}>
      {data && (
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>{data.subtitle}</h2>
            <p>{data.title}</p>
          </header>

          <div className="row gy-4">
            {data.data &&
              data.data.map((item) => (
                <div
                  key={item._id}
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay={item.delay}
                >
                  <div className={`service-box ${item.color}`}>
                    <i className="ri-discuss-line icon"></i>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    {/* <Link to={item.link} className="read-more">
                  <span>Read More</span> <i className="bi bi-arrow-right"></i>
                </Link> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </section>
  );
}

export default Services;
