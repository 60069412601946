import * as actionTypes from "../constants/itemConstants";
import axios from "axios";
import host from "../constants/hostConstant";
import { toast } from "react-toastify";

export const getBlogItems = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_ITEMS_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.get(`${host}/api/blogs`);
    dispatch({
      type: actionTypes.GET_ITEMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ITEMS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const getBlogItemDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_ITEM_DETAILS_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.get(`${host}/api/blogs/${id}`);
    dispatch({ type: actionTypes.GET_ITEM_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ITEM_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const postBlogItem = (item) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.POST_ITEM_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.post(`${host}/api/blogs`, item);
    dispatch({ type: actionTypes.POST_ITEM_SUCCESS, payload: data });
    window.location.replace("/admin-blogs-mgmt");
    toast(`Your blog has been successfully posted`);
  } catch (error) {
    dispatch({
      type: actionTypes.POST_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const updateBlogItem = (id, item) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.PUT_ITEM_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const body = { ...item };
    delete body._id;
    const { data } = await axios.put(`${host}/api/blogs/${id}`, body);
    dispatch({ type: actionTypes.POST_ITEM_SUCCESS, payload: data });
    window.location.replace("/admin-blogs-mgmt");
    toast(`Your blog has been successfully updated`);
  } catch (error) {
    dispatch({
      type: actionTypes.PUT_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const deleteBlogItem = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.DELETE_ITEM_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.delete(`${host}/api/blogs/${id}`);
    dispatch({ type: actionTypes.DELETE_ITEM_SUCCESS, payload: data });
    window.location.replace("/admin-blogs-mgmt");
    toast(`Your blog has been deleted`);
  } catch (error) {
    dispatch({
      type: actionTypes.DELETE_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const updateBlogItemViews = (id) => async (dispatch) => {
  try {
    await axios.put(`${host}/api/blogs/views/${id}`);
  } catch (error) {
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};
