import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/Breadcrumbs";
import notFound from "../assets/notFound/page-not-found.png";
import "../css/notFoundPage.css";

function NotFoundPage() {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("/");
    }, 5000);
  }, []);
  return (
    <>
      <Helmet>
        <title>DStudio Tech. | 404 Page Not Found</title>
      </Helmet>
      <main id="main">
        <Breadcrumbs title={"404 Page not found"} />
        <section class="not-found">
          <div className="container d-flex justify-content-center align-items-center">
            <img
              src={notFound}
              alt="Page Not Found"
              className="not-found-img"
            />
          </div>
        </section>
      </main>
    </>
  );
}

export default NotFoundPage;
