export const POST_USER_REQUEST = "POST_USER_REQUEST";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_FAIL = "POST_USER_FAIL";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";

export const POST_USER_IMG_REQUEST = "POST_USER_IMG_REQUEST";
export const POST_USER_IMG_PERCENTAGE = "POST_USER_IMG_PERCENTAGE";
export const POST_USER_IMG_SUCCESS = "POST_USER_IMG_SUCCESS";
export const POST_USER_IMG_FAIL = "POST_USER_IMG_FAIL";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";
export const GET_USER_DETAILS_RESET = "GET_USER_DETAILS_RESET";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const GET_USER_IMG_REQUEST = "GET_USER_IMG_REQUEST";
export const GET_USER_IMG_SUCCESS = "GET_USER_IMG_SUCCESS";
export const GET_USER_IMG_FAIL = "GET_USER_IMG_FAIL";
