import React from "react";

function FaqSection({ web, textChange, dataChange }) {
  return (
    <div className="admin-form tab-pane fade show" id="faq">
      <form>
        <h4 className="mb-3">FAQ Section</h4>
        <div className="form-group">
          <input
            name="title"
            onChange={textChange}
            value={web.faq.title}
            type="text"
            className="form-control"
            placeholder="Title"
          />
        </div>
        <div className="form-group">
          <input
            name="subtitle"
            onChange={textChange}
            value={web.faq.subtitle}
            type="text"
            className="form-control"
            placeholder="Subtitle"
          />
        </div>
        {web.faq.data &&
          web.faq.data.map((item, index) => (
            <div className="row" key={item._id}>
              <h6 className="mb-3">Item {index + 1}</h6>
              <div className="form-group">
                <input
                  name="question"
                  onChange={(e) => dataChange(e, item._id)}
                  value={item.question}
                  type="text"
                  className="form-control"
                  placeholder="Question"
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="solution"
                  value={item.solution}
                  rows="3"
                  onChange={(e) => dataChange(e, item._id)}
                  placeholder="Solution"
                ></textarea>
              </div>
            </div>
          ))}
      </form>
    </div>
  );
}

export default FaqSection;
