import React, { useState, useContext } from 'react';
import '../css/footer.css';
import { EnvContext } from '../App';
import logo from '../assets/logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { postNewsletterItem } from '../redux/actions/newsletterItemActions';

function Footer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { scroll, sectionRefs: refs } = useContext(EnvContext);
  const [newsletter, setNewsletter] = useState({});

  const handleTextChange = e => {
    const { name, value } = e.target;
    setNewsletter({ ...newsletter, [name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(postNewsletterItem(newsletter));
  };

  const pageScroll = async section => {
    await refs[section].current;
    if (refs[section].current) {
      if (scroll === 0) {
        window.scrollTo(0, refs[section].current.offsetTop + 1);
      } else {
        window.scrollTo(
          0,
          refs[section].current.offsetTop - refs.header.current.offsetHeight + 1
        );
      }
    } else {
      window.scrollTo(0, 0);
    }
  };

  const handlePageScroll = section => e => {
    e.preventDefault();
    if (refs[section].current && location.pathname === '/') {
      if (scroll === 0) {
        window.scrollTo(0, refs[section].current.offsetTop + 1);
      } else {
        window.scrollTo(
          0,
          refs[section].current.offsetTop - refs.header.current.offsetHeight + 1
        );
      }
    } else {
      navigate('/');
      pageScroll(section);
    }
  };

  return (
    <footer id="footer" className="footer" ref={refs.footer}>
      {location.pathname.includes('admin') ? null : (
        <div className="footer-newsletter">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center">
                <h4>Our Newsletter</h4>
                <p>
                  Stay ahead of the game with our exclusive tech insights!
                  Subscribe to our newsletter now and never miss out on the
                  latest updates.
                </p>
              </div>
              <div className="col-lg-6">
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="email"
                    onChange={handleTextChange}
                    placeholder="Your Email"
                  />
                  <input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <Link to="/" className="logo d-flex align-items-center">
                <img src={logo} alt="D Studio Logo" />
                <span>Studio</span>
              </Link>
              <p>
                DStudio Tech. is a dynamic and forward-thinking IT company that
                specializes in delivering innovative technology solutions to
                businesses. Our team of experienced professionals is passionate
                about leveraging the latest advancements in technology to help
                our clients achieve their goals and stay ahead of the
                competition.
              </p>
              <div className="social-links mt-3">
                <Link
                  target="_blank"
                  to="https://www.youtube.com/channel/UCVSI9K36DvRWkqmH4XMZzOw"
                  className="youtube"
                >
                  <i className="bi bi-youtube"></i>
                </Link>
                <Link
                  target="_blank"
                  to="https://www.facebook.com/profile.php?id=100093911182490"
                  className="facebook"
                >
                  <i className="bi bi-facebook"></i>
                </Link>
                <Link
                  target="_blank"
                  to="https://www.google.com/search?q=DStudio+Technology&stick=H4sIAAAAAAAA_-NgU1I1qEg0SU1OMTY3ME0xNjROS0yxMqgwSbI0NLIwMjUxMTZPNjU2WMQq5BJcUpqSma8QkpqckZefk59eCQBzDfJhPgAAAA&hl=en&mat=CSqKnI91HrTvElYBeenfiLVnmnUeJyfw7Jl1kp6zYm2V0EMqZaBe3aysBxgYYH0sM7rCYRifq2QbbueZdhu7Z_a13CWboaVS1Ho4Hid8hGw4XgiSe5cf9fxuGD_QY8U9Dw&authuser=0&bshm=lbsc/1"
                  className="google"
                >
                  <i className="bi bi-google"></i>
                </Link>
                <Link
                  target="_blank"
                  to="https://www.instagram.com/dstudiotech618/"
                  className="instagram"
                >
                  <i className="bi bi-instagram"></i>
                </Link>
              </div>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{' '}
                  <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                    Home
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{' '}
                  <Link onClick={handlePageScroll('about')}>About us</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link onClick={handlePageScroll('value')}>Our value</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link onClick={handlePageScroll('features')}>
                    Our features
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{' '}
                  <Link target="_blank" to="/admin-entry">
                    Admin entry
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link onClick={handlePageScroll('services')}>
                    Graphic Design
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{' '}
                  <Link onClick={handlePageScroll('services')}>
                    Web & App Design
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link onClick={handlePageScroll('services')}>
                    Web Development
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>
                  <Link onClick={handlePageScroll('services')}>
                    Mobile App Development
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{' '}
                  <Link onClick={handlePageScroll('services')}>
                    Digital Marketing
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                S4 Level 7, 243 Edward Street <br />
                Brisbane, 4000
                <br />
                Australia <br />
                <br />
                <strong>Phone:</strong> (+61)0404 368 885
                <br />
                <strong>Email:</strong> info@dstutiotech.com
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright {new Date().getFullYear()}{' '}
          <strong>
            <span>DStudio Technology</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Designed by{' '}
          <Link target="_blank" to="https://www.dstudio.top-oz.com/">
            David Fan
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
