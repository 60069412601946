import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function ImageUpload({ imageChange, imageSubmit }) {
  const [file, setFile] = useState({});
  const { uploadPercentage, postedFile } = useSelector(
    (state) => state.postUserImg
  );

  useEffect(() => {
    postedFile && setFile(postedFile);
  }, [postedFile]);
  return (
    <div className="admin-form tab-pane fade show" id="img">
      <form>
        <h4 className="mb-3">Upload Image</h4>
        <div className="form-group">
          <div className="input-group">
            <input
              className="form-control"
              type="file"
              id="formFile"
              onChange={imageChange}
            />
            <button
              className="btn btn-primary"
              type="submit"
              onClick={imageSubmit}
            >
              Upload
            </button>
          </div>
          <div className="progress">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: `${uploadPercentage}%` }}
            >
              {uploadPercentage}%
            </div>
          </div>
        </div>
      </form>
      <div className="row d-flex justify-content-center">
        <div className="col-lg-8 text-center">
          {file && <p>Image path: {file.filePath}</p>}

          <img
            className="img-fluid"
            src={file && file.filePath}
            alt={file && file.fileName}
          />
        </div>
      </div>
    </div>
  );
}

export default ImageUpload;
