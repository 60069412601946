import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import { EnvContext } from "../App";
import "../css/counter.css";

function Counter() {
  const { scroll, sectionRefs: refs } = useContext(EnvContext);
  const [counterActive, setCounterActive] = useState(false);
  const [data, setData] = useState([]);

  const { loading, item } = useSelector((state) => state.getWebItemDetails);

  useEffect(() => {
    if (!loading) setData(item.counter);
  }, [loading, item]);

  useEffect(() => {
    setCounterActive(
      scroll > 0 &&
        scroll >=
          refs.counter.current.offsetTop - refs.header.current.offsetHeight
    );
  }, [scroll]);

  return (
    <section id="counts" className="counts" ref={refs.counter}>
      {data && (
        <div className="container" data-aos="fade-up">
          <div className="row gy-4">
            {data.map((item) => (
              <div className="col-lg-3 col-md-6" key={item._id}>
                <div className="count-box">
                  <i className={item.icon} style={{ color: item.color }}></i>
                  <div>
                    <span>
                      {counterActive ? (
                        <CountUp end={item.end} duration={item.duration} />
                      ) : (
                        item.end
                      )}
                    </span>
                    <p>{item.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
}

export default Counter;
