import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import "../css/header.css";
import NavBar from "./NavBar";
import { EnvContext } from "../App";
import logo from "../assets/logo.png";

function Header() {
  const location = useLocation();
  const { scroll, sectionRefs } = useContext(EnvContext);

  return (
    <header
      id="header"
      ref={sectionRefs.header}
      className={`header fixed-top d-flex align-items-center header-transparent ${
        location.pathname !== "/" ? "white" : undefined
      } ${scroll > 100 ? "header-scrolled" : undefined}`}
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center">
          <img src={logo} alt="D Studio Logo" />
          <span>Studio</span>
        </a>
        <NavBar />
      </div>
    </header>
  );
}

export default Header;
