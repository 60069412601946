import React from "react";

function FeaturesSection({
  web,
  textChange,
  tabChange,
  featuresDataChange,
  featuresPartTextChange,
}) {
  return (
    <div className="admin-form tab-pane fade show" id="features">
      <form>
        <h4 className="mb-3">Features Section</h4>
        <div className="form-group">
          <input
            name="title"
            onChange={textChange}
            value={web.features.title}
            type="text"
            className="form-control"
            placeholder="Title"
          />
        </div>
        <div className="form-group">
          <input
            name="subtitle"
            onChange={textChange}
            value={web.features.subtitle}
            type="text"
            className="form-control"
            placeholder="Subtitle"
          />
        </div>
      </form>
      <ul className="nav nav-pills mb-3 justify-content-center">
        <li>
          <a
            className="nav-link active"
            data-bs-toggle="pill"
            href="#part1"
            onClick={() => tabChange("partOne")}
          >
            Part One
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            data-bs-toggle="pill"
            href="#part2"
            onClick={() => tabChange("partTwo")}
          >
            Part Two
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            data-bs-toggle="pill"
            href="#part3"
            onClick={() => tabChange("partThree")}
          >
            Part Three
          </a>
        </li>
      </ul>

      <div className="tab-content">
        <div className="admin-form tab-pane fade show active" id="part1">
          <form>
            <h4 className="mb-3">Features Part One</h4>
            <div className="form-group">
              <input
                name="image"
                value={web.features.partOne.image}
                onChange={featuresPartTextChange}
                type="text"
                className="form-control"
                placeholder="Image"
              />
            </div>
            {web.features.partOne.data &&
              web.features.partOne.data.map((item, index) => (
                <div className="row" key={item._id}>
                  <h6 className="mb-3">Item {index + 1}</h6>
                  <div className="form-group">
                    <input
                      name="name"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.name}
                      type="text"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      name="icon"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.icon}
                      type="text"
                      className="form-control"
                      placeholder="Icon"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      name="delay"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.delay}
                      type="text"
                      className="form-control"
                      placeholder="Delay"
                    />
                  </div>
                </div>
              ))}
          </form>
        </div>
        <div className="admin-form tab-pane fade show" id="part2">
          <form>
            <h4 className="mb-3">Features Part Two</h4>
            <div className="form-group">
              <input
                name="title"
                value={web.features.partTwo.title}
                onChange={featuresPartTextChange}
                type="text"
                className="form-control"
                placeholder="Title"
              />
            </div>
            <div className="form-group">
              <input
                name="image"
                value={web.features.partTwo.image}
                onChange={featuresPartTextChange}
                type="text"
                className="form-control"
                placeholder="Image"
              />
            </div>
            <div className="row">
              <h6 className="mb-3">Part Two Tabs</h6>
              {web.features.partTwo.tabs &&
                web.features.partTwo.tabs.map((tab) => (
                  <div className="form-group col-md-4" key={tab._id}>
                    <input
                      name="name"
                      onChange={(e) => featuresDataChange(e, "tabs", tab._id)}
                      value={tab.name}
                      type="text"
                      className="form-control"
                      placeholder="Tab Name"
                    />
                  </div>
                ))}
            </div>
            {web.features.partTwo.data &&
              web.features.partTwo.data.map((item, index) => (
                <div className="row" key={item._id}>
                  <h6 className="mb-3">Tab Content {index + 1}</h6>
                  <div className="form-group">
                    <textarea
                      name="description"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.description}
                      type="text"
                      rows="3"
                      className="form-control"
                      placeholder="Description"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <input
                      name="firstTitle"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.firstTitle}
                      type="text"
                      className="form-control"
                      placeholder="First Title"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      name="partOne"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.partOne}
                      type="text"
                      className="form-control"
                      placeholder="Part One"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      name="secondTitle"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.secondTitle}
                      type="text"
                      className="form-control"
                      placeholder="Second Title"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      name="partTwo"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.partTwo}
                      type="text"
                      className="form-control"
                      placeholder="Part Two"
                    />
                  </div>
                </div>
              ))}
          </form>
        </div>
        <div className="admin-form tab-pane fade show" id="part3">
          <form>
            <h4 className="mb-3">Features Part Three</h4>
            <div className="form-group">
              <input
                name="title"
                value={web.features.partThree.title}
                onChange={featuresPartTextChange}
                type="text"
                className="form-control"
                placeholder="Title"
              />
            </div>
            <div className="form-group">
              <input
                name="image"
                value={web.features.partThree.image}
                onChange={featuresPartTextChange}
                type="text"
                className="form-control"
                placeholder="Image"
              />
            </div>
            {web.features.partThree.data &&
              web.features.partThree.data.map((item, index) => (
                <div className="row" key={item._id}>
                  <h6 className="mb-3">Item {index + 1}</h6>
                  <div className="form-group">
                    <input
                      name="name"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.name}
                      type="text"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      name="description"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.description}
                      type="text"
                      rows="3"
                      className="form-control"
                      placeholder="Description"
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <input
                      name="icon"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.icon}
                      type="text"
                      className="form-control"
                      placeholder="Icon"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      name="delay"
                      onChange={(e) => featuresDataChange(e, "data", item._id)}
                      value={item.delay}
                      type="text"
                      className="form-control"
                      placeholder="Delay"
                    />
                  </div>
                </div>
              ))}
          </form>
        </div>
      </div>
    </div>
  );
}

export default FeaturesSection;
