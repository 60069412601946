import * as actionTypes from "../constants/itemConstants";
import axios from "axios";
import host from "../constants/hostConstant";
import { toast } from "react-toastify";

export const getWebItems = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_ITEMS_REQUEST });
    const { data } = await axios.get(`${host}/api/web`);
    dispatch({
      type: actionTypes.GET_ITEMS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ITEMS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const getWebItemDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GET_ITEM_DETAILS_REQUEST });
    // axios.defaults.headers.common["x-auth-token"] =
    //   localStorage.getItem("token");
    const { data } = await axios.get(`${host}/api/web/${id}`);
    dispatch({ type: actionTypes.GET_ITEM_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_ITEM_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const postWebItem = (item) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.POST_ITEM_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.post(`${host}/api/web`, item);
    dispatch({ type: actionTypes.POST_ITEM_SUCCESS, payload: data });
    window.location.replace("/admin-blogs-mgmt");
    toast(`Your blog has been successfully posted`);
  } catch (error) {
    dispatch({
      type: actionTypes.POST_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const updateWebItem = (id, item) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.PUT_ITEM_REQUEST });
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const body = { ...item };
    delete body._id;
    const { data } = await axios.put(`${host}/api/web/${id}`, body);
    dispatch({ type: actionTypes.POST_ITEM_SUCCESS, payload: data });
    toast(`Your web content has been successfully updated`);
  } catch (error) {
    dispatch({
      type: actionTypes.PUT_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};
