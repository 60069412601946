import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../components/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import Preloader from "../components/Preloader";
import {
  getWebItemDetails,
  updateWebItem,
} from "../redux/actions/webItemActions";
import { getCurrentUser } from "../redux/reducers/userReducers";
import { postUserImg } from "../redux/actions/userActions";
import "../css/adminContentMgmt.css";
import { toast } from "react-toastify";
import HeroSection from "../components/adminContentMgmt/HeroSection";
import AboutSection from "../components/adminContentMgmt/AboutSection";
import ValueSection from "../components/adminContentMgmt/ValueSection";
import CounterSection from "../components/adminContentMgmt/CounterSection";
import FeaturesSection from "../components/adminContentMgmt/FeaturesSection";
import ServicesSection from "../components/adminContentMgmt/ServicesSection";
import PricingSection from "../components/adminContentMgmt/PricingSection";
import TestimonialsSection from "../components/adminContentMgmt/TestimonialsSection";
import FaqSection from "../components/adminContentMgmt/FaqSection";
import ImageUpload from "../components/adminContentMgmt/ImageUpload";

function AdminContentMgmt() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = getCurrentUser();

  const [web, setWeb] = useState({});
  const [tab, setTab] = useState("hero");
  const [featuresTab, setFeaturesTab] = useState("partOne");
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("");
  const webId = "6417aa6764ed8273c5d4c8c7";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !user && navigate("/admin-entry");
  }, []);

  useEffect(() => {
    dispatch(getWebItemDetails(webId));
  }, [dispatch]);

  const { loading, item, error } = useSelector(
    (state) => state.getWebItemDetails
  );

  const { uploadPercentage, postedFile } = useSelector(
    (state) => state.postUserImg
  );

  useEffect(() => {
    setWeb(item);
  }, [item]);

  // update the uploaded image path in web state
  const updateImageRoute = (tab, path) => {
    switch (tab) {
      case "hero":
        setWeb({ ...web, hero: { ...web.hero, image: path } });
        break;
      case "about":
        setWeb({ ...web, about: { ...web.about, image: path } });
        break;
      case "img":
        break;
    }
  };

  useEffect(() => {
    postedFile && updateImageRoute(tab, postedFile.filePath);
  }, [postedFile]);

  // handle input text change in web (1st level)
  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setWeb({ ...web, [tab]: { ...web[tab], [name]: value } });
  };

  // handle input text change in web data array (2nd level)
  const handleDataTextChange = (e, id) => {
    const { name, value } = e.target;
    const newData = web[tab].data.map((item) => {
      if (item._id === id) {
        return { ...item, [name]: value };
      } else {
        return item;
      }
    });
    setWeb({ ...web, [tab]: { ...web[tab], data: newData } });
  };

  // handle counter input text change (array 1st level)
  const handleCounterTextChange = (e, id) => {
    const { name, value } = e.target;
    const newData = web[tab].map((item) => {
      if (item._id === id) {
        return { ...item, [name]: value };
      } else {
        return item;
      }
    });
    setWeb({ ...web, [tab]: newData });
  };

  // handle the price featured change (2nd level)
  const handlePriceFeaturedChange = (id) => {
    const newData = web[tab].data.map((item) => {
      if (item._id === id) {
        return { ...item, featured: !item.featured };
      } else {
        return item;
      }
    });
    setWeb({ ...web, [tab]: { ...web[tab], data: newData } });
  };

  // handle price list items text change (3rd level)
  const handlePriceListTextChange = (e, dataId, listId) => {
    const { name, value } = e.target;
    const newData = web[tab].data.map((item) => {
      if (item._id === dataId) {
        const newList = item.list.map((el) => {
          if (el._id === listId) {
            return { ...el, [name]: value };
          } else {
            return el;
          }
        });
        return { ...item, list: newList };
      } else {
        return item;
      }
    });
    setWeb({ ...web, [tab]: { ...web[tab], data: newData } });
  };

  // handle price list items available change (3rd level)
  const handlePriceListAvailableChange = (dataId, listId) => {
    const newData = web[tab].data.map((item) => {
      if (item._id === dataId) {
        const newList = item.list.map((el) => {
          if (el._id === listId) {
            return { ...el, na: !el.na };
          } else {
            return el;
          }
        });
        return { ...item, list: newList };
      } else {
        return item;
      }
    });
    setWeb({ ...web, [tab]: { ...web[tab], data: newData } });
  };

  //handle features tabs and data change
  const handleFeaturesDataChange = (e, target, id) => {
    const { name, value } = e.target;
    const newData = web.features[featuresTab][target].map((item) => {
      if (item._id === id) {
        return { ...item, [name]: value };
      } else {
        return item;
      }
    });
    setWeb({
      ...web,
      features: {
        ...web.features,
        [featuresTab]: { ...web.features[featuresTab], [target]: newData },
      },
    });
  };

  // handle features part text change
  const handleFeaturesPartTextChange = (e) => {
    const { name, value } = e.target;
    setWeb({
      ...web,
      features: {
        ...web.features,
        [featuresTab]: { ...web.features[featuresTab], [name]: value },
      },
    });
  };

  // handle tab change
  const handelTabChage = (tab = "hero") => {
    setTab(tab);
  };

  // handle features tab change
  const handelFeaturesTabChage = (tab = "partOne") => {
    setFeaturesTab(tab);
  };

  // Image change and upload
  const onImageChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  // Image upload
  const handleImgSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    dispatch(postUserImg(formData));
  };

  // Form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateWebItem(webId, web));
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <>
          <Helmet>
            <title>DStudio Tech. | Admin | Web Content Mgmt.</title>
          </Helmet>
          <main id="main">
            <Breadcrumbs title="Content Management" />
            <section className="admin-content-mgmt">
              <div className="container">
                <div className="row justify-content-center">
                  <div
                    className="col-lg-10"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <ul className="nav nav-pills mb-3 justify-content-center">
                      <li>
                        <a
                          className="nav-link active"
                          data-bs-toggle="pill"
                          href="#hero"
                          onClick={() => handelTabChage("hero")}
                        >
                          Hero
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#about"
                          onClick={() => handelTabChage("about")}
                        >
                          About
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#value"
                          onClick={() => handelTabChage("value")}
                        >
                          Value
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#counter"
                          onClick={() => handelTabChage("counter")}
                        >
                          Counter
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#features"
                          onClick={() => handelTabChage("features")}
                        >
                          Features
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#services"
                          onClick={() => handelTabChage("services")}
                        >
                          Services
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#pricing"
                          onClick={() => handelTabChage("pricing")}
                        >
                          Pricing
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#testimonials"
                          onClick={() => handelTabChage("testimonials")}
                        >
                          Testimonials
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#faq"
                          onClick={() => handelTabChage("faq")}
                        >
                          FAQ
                        </a>
                      </li>
                      <li>
                        <a
                          className="nav-link"
                          data-bs-toggle="pill"
                          href="#img"
                          onClick={() => handelTabChage("img")}
                        >
                          Image
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      {web && web.hero && (
                        <HeroSection
                          web={web}
                          textChange={handleTextChange}
                          imageChange={onImageChange}
                          imageSubmit={handleImgSubmit}
                        />
                      )}
                      {web && web.about && (
                        <AboutSection
                          web={web}
                          textChange={handleTextChange}
                          imageChange={onImageChange}
                          imageSubmit={handleImgSubmit}
                        />
                      )}
                      {web && web.value && (
                        <ValueSection
                          web={web}
                          textChange={handleTextChange}
                          dataChange={handleDataTextChange}
                        />
                      )}
                      {web && web.counter && (
                        <CounterSection
                          web={web}
                          textChange={handleCounterTextChange}
                        />
                      )}
                      {web && web.features && (
                        <FeaturesSection
                          web={web}
                          textChange={handleTextChange}
                          tabChange={handelFeaturesTabChage}
                          featuresDataChange={handleFeaturesDataChange}
                          featuresPartTextChange={handleFeaturesPartTextChange}
                        />
                      )}
                      {web && web.services && (
                        <ServicesSection
                          web={web}
                          textChange={handleTextChange}
                          dataChange={handleDataTextChange}
                        />
                      )}
                      {web && web.pricing && (
                        <PricingSection
                          web={web}
                          textChange={handleTextChange}
                          dataChange={handleDataTextChange}
                          dataFeaturedChange={handlePriceFeaturedChange}
                          dataPriceListChange={handlePriceListTextChange}
                          dataPriceAvailableChange={
                            handlePriceListAvailableChange
                          }
                        />
                      )}
                      {web && web.testimonials && (
                        <TestimonialsSection
                          web={web}
                          textChange={handleTextChange}
                          dataChange={handleDataTextChange}
                        />
                      )}
                      {web && web.faq && (
                        <FaqSection
                          web={web}
                          textChange={handleTextChange}
                          dataChange={handleDataTextChange}
                        />
                      )}
                      {web && (
                        <ImageUpload
                          imageChange={onImageChange}
                          imageSubmit={handleImgSubmit}
                          percentage={uploadPercentage}
                          file={postedFile}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default AdminContentMgmt;
