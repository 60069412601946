import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getBlogItems,
  updateBlogItemViews,
} from "../redux/actions/blogItemActions";
import { Link } from "react-router-dom";
import paginate from "../utils/paginate";
import Breadcrumbs from "../components/Breadcrumbs";
import Preloader from "../components/Preloader";
import BlogSideBar from "../components/BlogSideBar";
import Pagination from "../components/Pagination";
import "../css/blogsPage.css";

function BlogsPage() {
  const dispatch = useDispatch();
  const [blogs, setBlogs] = useState([]);
  const [resultItems, setResultItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemCount, setItemCount] = useState(0);
  const pageSize = 2;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getBlogItems());
  }, [dispatch]);

  const { loading, items, error } = useSelector((state) => state.getBlogItems);

  useEffect(() => {
    setResultItems(items);
  }, [items]);

  useEffect(() => {
    const pagedItems = paginate(resultItems, currentPage, pageSize);
    setItemCount(resultItems.length);
    setBlogs(pagedItems);
  }, [resultItems, currentPage, pageSize]);

  const handleOnPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchBlogs = (e, item) => {
    e.preventDefault();
    const searchedBlogs = items.filter((blog) =>
      blog.blogTitle.toLowerCase().includes(item.title.toLowerCase())
    );
    setResultItems(searchedBlogs);
  };

  const handleSearchBlogsByCategory = (category) => {
    const searchedBlogs = items.filter((blog) =>
      blog.category.toLowerCase().includes(category.toLowerCase())
    );
    setResultItems(searchedBlogs);
  };

  const handleSearchBlogsByTag = (tag) => {
    const searchedBlogs = items.filter((blog) => blog.tags.includes(tag));
    setResultItems(searchedBlogs);
  };

  return (
    <>
      <Helmet>
        <title>DStudio Tech. | Blog</title>
      </Helmet>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <main id="main">
          <Breadcrumbs title={"Blog"} />
          <section id="blog" className="blog">
            <div className="container" data-aos="fade-up">
              <div className="row">
                <div className="col-lg-8 entries">
                  {resultItems.length === 0 ? (
                    <div className="row not-found">
                      <div className="container text-center">
                        <h3 className="mt-3">Sorry, no blogs found...</h3>
                        <i className="ri-delete-bin-6-line"></i>
                        <p className="mb-3">
                          Please try another search keyword
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      {blogs.map((item) => (
                        <article className="entry" key={item._id}>
                          <div className="entry-img">
                            <img
                              src={item.blogImage}
                              alt="Blog Image"
                              className="img-fluid"
                            />
                          </div>

                          <h2 className="entry-title">
                            <Link
                              to={`/blogs/${item._id}`}
                              onClick={updateBlogItemViews(item._id)}
                            >
                              {item.blogTitle}
                            </Link>
                          </h2>

                          <div className="entry-meta">
                            <ul>
                              <li className="d-flex align-items-center">
                                <i className="bi bi-person"></i>{" "}
                                <Link
                                  to={`/blogs/${item._id}`}
                                  onClick={updateBlogItemViews(item._id)}
                                >
                                  {item.author.name}
                                </Link>
                              </li>
                              <li className="d-flex align-items-center">
                                <i className="bi bi-clock"></i>{" "}
                                <Link
                                  to={`/blogs/${item._id}`}
                                  onClick={updateBlogItemViews(item._id)}
                                >
                                  <time dateTime={item.insertDate}>
                                    {item.insertDate
                                      .toString()
                                      .substring(0, 10)}
                                  </time>
                                </Link>
                              </li>
                              <li className="d-flex align-items-center">
                                <i className="bi bi-eye"></i>{" "}
                                <Link
                                  to={`/blogs/${item._id}`}
                                  onClick={updateBlogItemViews(item._id)}
                                >
                                  {item.views} Views
                                </Link>
                              </li>
                            </ul>
                          </div>

                          <div className="entry-content">
                            <p>
                              {item.section[0].paragraph.substring(0, 300)} ...
                            </p>
                            <div className="read-more">
                              <Link
                                to={`/blogs/${item._id}`}
                                onClick={updateBlogItemViews(item._id)}
                              >
                                Read More
                              </Link>
                            </div>
                          </div>
                        </article>
                      ))}
                      <Pagination
                        itemCount={itemCount}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={handleOnPageChange}
                      />
                    </>
                  )}
                </div>
                <BlogSideBar
                  searchBlogs={handleSearchBlogs}
                  searchBlogsByCategory={handleSearchBlogsByCategory}
                  searchBlogsByTag={handleSearchBlogsByTag}
                />
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
}

export default BlogsPage;
