import * as actionTypes from "../constants/userConstants";
import jwtDecode from "jwt-decode";

export const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem("token");
    const user = jwtDecode(jwt);
    return user;
  } catch (error) {
    return null;
  }
};

const currentUser = getCurrentUser();

export const postUserReducer = (state = { user: currentUser }, action) => {
  switch (action.type) {
    case actionTypes.POST_USER_REQUEST:
      return {
        loading: true,
        user: {},
      };
    case actionTypes.POST_USER_SUCCESS:
      const tokenKey = "token";
      localStorage.setItem(tokenKey, action.payload);
      return {
        loading: false,
        user: jwtDecode(localStorage.getItem(tokenKey)),
      };
    case actionTypes.POST_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createUserReducer = (state = { user: currentUser }, action) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_REQUEST:
      return {
        loading: true,
        user: {},
      };
    case actionTypes.CREATE_USER_SUCCESS:
      //const tokenKey = "token";
      //localStorage.setItem(tokenKey, action.payload.headers["x-auth-token"]);
      return {
        loading: false,
        user: action.payload.data,
      };
    case actionTypes.CREATE_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_REQUEST:
      return {
        loading: true,
        user: {},
      };
    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        loading: false,
        user: action.payload,
      };
    case actionTypes.UPDATE_USER_FAIL:
      return {
        laoding: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const postUserImgReducer = (state = { uploadPercentage: 0 }, action) => {
  switch (action.type) {
    case actionTypes.POST_USER_IMG_REQUEST:
      return {
        loading: true,
        uploadPercentage: 0,
        postedFile: {},
      };
    case actionTypes.POST_USER_IMG_PERCENTAGE:
      return {
        loading: false,
        uploadPercentage: action.payload,
      };
    case actionTypes.POST_USER_IMG_SUCCESS:
      return {
        loading: false,
        uploadPercentage: 100,
        postedFile: action.payload,
      };
    case actionTypes.POST_USER_IMG_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getUserDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_DETAILS_REQUEST:
      return {
        loading: true,
        user: {},
      };
    case actionTypes.GET_USER_DETAILS_SUCCESS:
      return {
        loading: false,
        user: action.payload,
      };
    case actionTypes.GET_USER_DETAILS_FAIL:
      return {
        laoding: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getUserImgUrlReducer = (state = { userImg: {} }, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_IMG_REQUEST:
      return {
        loading: true,
        userImg: {},
      };
    case actionTypes.GET_USER_IMG_SUCCESS:
      return {
        loading: false,
        userImg: action.payload,
      };

    case actionTypes.GET_USER_IMG_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
