import React from "react";

function CounterSection({ web, textChange }) {
  return (
    <div className="admin-form tab-pane fade show" id="counter">
      <form>
        <h4 className="mb-3">Counter Section</h4>
        {web.counter &&
          web.counter.map((item) => (
            <div className="row" key={item._id}>
              <h6 className="mb-2">Counter: {item.name}</h6>
              <div className="form-group col-lg-3 col-md-6 col-sm-12">
                <input
                  name="end"
                  onChange={(e) => textChange(e, item._id)}
                  value={item.end}
                  type="text"
                  className="form-control"
                  placeholder="End"
                />
              </div>
              <div className="form-group col-lg-3 col-md-6 col-sm-12">
                <input
                  name="name"
                  onChange={(e) => textChange(e, item._id)}
                  value={item.name}
                  type="text"
                  className="form-control"
                  placeholder="Name"
                />
              </div>
              <div className="form-group col-lg-3 col-md-6 col-sm-12">
                <input
                  name="icon"
                  onChange={(e) => textChange(e, item._id)}
                  value={item.icon}
                  type="text"
                  className="form-control"
                  placeholder="Icon"
                />
              </div>
              <div className="form-group col-lg-3 col-md-6 col-sm-12">
                <input
                  name="color"
                  onChange={(e) => textChange(e, item._id)}
                  value={item.color}
                  type="text"
                  className="form-control"
                  placeholder="Color"
                />
              </div>
            </div>
          ))}
      </form>
    </div>
  );
}

export default CounterSection;
