import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";

function AdminDataStatistic() {
  return (
    <main id="main">
      <Breadcrumbs title="Admin Data Statistic" />
      <section className="inner-page">
        <div className="container">
          <p>This Section is pending on development</p>
        </div>
      </section>
    </main>
  );
}

export default AdminDataStatistic;
