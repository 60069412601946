import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { getWebItemDetails } from '../redux/actions/webItemActions';
import Main from '../components/Main';
import Hero from '../components/Hero';

function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWebItemDetails('6417aa6764ed8273c5d4c8c7'));
  }, [dispatch]);
  return (
    <>
      <Helmet>
        <title>DStudio Technology | Your Business IT Solution</title>
      </Helmet>
      <Hero />
      <Main />
    </>
  );
}

export default Home;
