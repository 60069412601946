import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/Breadcrumbs";
import "../css/trainingPage.css";
import { Link } from "react-router-dom";
import { getTrainingItems } from "../redux/actions/trainingItemActions";
import { useDispatch, useSelector } from "react-redux";
import Preloader from "../components/Preloader";
import { toast } from "react-toastify";

function TrainingPage() {
  const dispatch = useDispatch();
  const [trainings, setTrainings] = useState([]);
  useEffect(() => {
    dispatch(getTrainingItems());
  }, [dispatch]);

  const { loading, items, error } = useSelector(
    (state) => state.getTrainingItems
  );

  useEffect(() => {
    setTrainings(items);
  }, [items]);
  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <>
          <Helmet>
            <title>DStudio Tech. | IT Training</title>
          </Helmet>
          <main id="main">
            <Breadcrumbs title="Training" />
            <section id="training" className="training">
              <div className="container" data-aos="fade-up">
                <header className="section-header">
                  <h2>Training</h2>
                  <p>Check our skill training vedios</p>
                </header>
                <div
                  className="row gy-4 posts-list"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  {trainings.map((item) => (
                    <div className="col-xl-4 col-md-6 mb-4" key={item._id}>
                      <article>
                        <div className="post-img">
                          <img
                            src={item.thumnail}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <p className="post-category">{item.category}</p>
                        <h2 className="title">
                          <Link to={`/training/${item._id}`}>
                            {item.title.substring(0, 60)}
                          </Link>
                        </h2>
                        <div className="d-flex align-items-center">
                          <img
                            src={item.avatar}
                            alt="Pulisher Photo"
                            className="img-fluid post-author-img flex-shrink-0"
                          />
                          <div className="post-meta">
                            <p className="post-author-list">{item.publisher}</p>
                            <p className="post-date">
                              <time dateTime={item.insertDate}>
                                {item.insertDate.toString().substring(0, 10)}
                              </time>
                            </p>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default TrainingPage;
