import React from "react";
import "../css/modal.css";

function Modal({ handleQueryAction, id, name, body, title }) {
  return (
    <div
      className="modal fade"
      id={name}
      tabIndex="-1"
      aria-labelledby="queryModalLabel"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog ${
          name === "queryViewModal" ? "modal-lg" : "modal-dialog-centered"
        }`}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="queryModalLabel">
              {title}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {name === "queryDeleteModal" ? (
              body
            ) : (
              <div>
                <p>
                  Query date:{" "}
                  {body.insertDate && body.insertDate.toString().slice(0, 10)}
                </p>
                <h5>
                  Client: {body.title} {body.name}
                </h5>
                <p>
                  Position: {body.position} <br />
                  Email: <a href={`mailto:${body.email}`}>{body.email} </a>
                  <br />
                  Phone: {body.phone}
                </p>
                <h5>Business: {body.business}</h5>
                <p>
                  Size: {body.size} <br />
                  Industry: {body.industry}
                </p>
                <h5>Service: {body.service}</h5>
                <h6>Message:</h6>
                <p>{body.message}</p>
              </div>
            )}
          </div>
          {name === "queryDeleteModal" && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-modal-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary btn-modal"
                data-bs-dismiss="modal"
                onClick={() => handleQueryAction(id)}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Modal;
