import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { EnvContext } from "../App";
import "../css/backToTop.css";

function BackToTop() {
  const { scroll } = useContext(EnvContext);
  return (
    <Link
      onClick={() => window.scrollTo(0, 0)}
      className={`back-to-top d-flex align-items-center justify-content-center ${
        scroll > 100 ? "active" : undefined
      }`}
    >
      <i className="bi bi-arrow-up-short"></i>
    </Link>
  );
}

export default BackToTop;
