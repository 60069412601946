import React, { useContext } from "react";
import "../css/preloader.css";
import { EnvContext } from "../App";

function Preloader() {
  const { sectionRefs: refs } = useContext(EnvContext);
  return <div id="preloader" ref={refs.preloader}></div>;
}

export default Preloader;
