import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import "../css/faq.css";
import { EnvContext } from "../App";

function Faq() {
  const { sectionRefs: refs } = useContext(EnvContext);
  const [data, setData] = useState({});
  const { loading, item } = useSelector((state) => state.getWebItemDetails);

  useEffect(() => {
    if (!loading) setData(item.faq);
  }, [loading, item]);

  return (
    <section id="faq" className="faq" ref={refs.faq}>
      {data && (
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>{data.subtitle}</h2>
            <p>{data.title}</p>
          </header>

          {data.data && (
            <div className="row">
              <div className="col-lg-6">
                <div className="accordion accordion-flush" id="faqlist1">
                  {data.data
                    .slice(0, Math.ceil(data.data.length / 2))
                    .map((item) => (
                      <div className="accordion-item" key={item._id}>
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#faq-content-${item._id}`}
                          >
                            {item.question}
                          </button>
                        </h2>
                        <div
                          id={`faq-content-${item._id}`}
                          className="accordion-collapse collapse"
                          data-bs-parent="#faqlist1"
                        >
                          <div className="accordion-body">{item.solution}</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="accordion accordion-flush" id="faqlist2">
                  {data.data
                    .slice(Math.ceil(data.data.length / 2), data.data.length)
                    .map((item) => (
                      <div className="accordion-item" key={item._id}>
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#faq-content-${item._id}`}
                          >
                            {item.question}
                          </button>
                        </h2>
                        <div
                          id={`faq-content-${item._id}`}
                          className="accordion-collapse collapse"
                          data-bs-parent="#faqlist2"
                        >
                          <div className="accordion-body">{item.solution}</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
}

export default Faq;
