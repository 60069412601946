import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { updateBlogItemViews } from '../redux/actions/blogItemActions';
import { getBlogItems } from '../redux/actions/blogItemActions';
import { Link, useLocation } from 'react-router-dom';

function BlogSideBar({ searchBlogs, searchBlogsByCategory, searchBlogsByTag }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [blogs, setBlogs] = useState([]);
  const [text, setText] = useState({});
  const [tags, setTags] = useState([]);
  const [sortColumn, setSortColumn] = useState({
    path: 'insertDate',
    order: 'desc',
  });

  useEffect(() => {
    location.pathname !== '/blogs' && dispatch(getBlogItems());
  }, [dispatch, location.pathname]);

  const { items } = useSelector(state => state.getBlogItems);

  const createTags = items => {
    let resultTags = [];
    items.map(item => {
      item.tags &&
        item.tags.map(tag => {
          if (!resultTags.includes(tag)) {
            resultTags.push(tag);
          }
        });
    });
    return resultTags;
  };

  useEffect(() => {
    const sortedItems = _.orderBy(items, [sortColumn.path], [sortColumn.order]);
    if (sortedItems.length < 5) setBlogs(sortedItems);
    setBlogs(sortedItems.slice(0, 5));
    setTags(createTags(items));
  }, [items, sortColumn]);

  const handleTextChange = e => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value });
  };

  const categoryCounter = category => {
    let counter = 0;
    items.map(item => {
      if (item.category === category) {
        counter++;
      }
    });
    return counter;
  };

  return (
    <div className="col-lg-4">
      <div className="sidebar">
        {location.pathname === '/blogs' && (
          <>
            <h3 className="sidebar-title">Search</h3>
            <div className="sidebar-item search-form">
              <form onSubmit={e => searchBlogs(e, text)}>
                <input
                  type="text"
                  name="title"
                  value={text.title ? text.title : ''}
                  onChange={handleTextChange}
                />
                <button type="submit">
                  <i className="bi bi-search"></i>
                </button>
              </form>
            </div>
            <h3 className="sidebar-title">Categories</h3>
            <div className="sidebar-item categories">
              <ul>
                <li>
                  <a onClick={() => searchBlogsByCategory('Web Design')}>
                    Web Design <span>({categoryCounter('Web Design')})</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => searchBlogsByCategory('Web Development')}>
                    Web Development{' '}
                    <span>({categoryCounter('Web Development')})</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => searchBlogsByCategory('App Development')}>
                    App Development{' '}
                    <span>({categoryCounter('App Development')})</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => searchBlogsByCategory('Graphic Design')}>
                    Graphic Design{' '}
                    <span>({categoryCounter('Graphic Design')})</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => searchBlogsByCategory('Digital Marketing')}>
                    Digital Marketing{' '}
                    <span>({categoryCounter('Digital Marketing')})</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => searchBlogsByCategory('Data Analytics')}>
                    Data Analytics{' '}
                    <span>({categoryCounter('Data Analytics')})</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => searchBlogsByCategory('IT Skills')}>
                    IT Skills <span>({categoryCounter('IT Skills')})</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => searchBlogsByCategory('Technology')}>
                    Technology <span>({categoryCounter('Technology')})</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => searchBlogsByCategory('Info System')}>
                    Info System <span>({categoryCounter('Info System')})</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => searchBlogsByCategory('AI')}>
                    AI <span>({categoryCounter('AI')})</span>
                  </a>
                </li>
                <li>
                  <a onClick={() => searchBlogsByCategory('Others')}>
                    Others <span>({categoryCounter('Others')})</span>
                  </a>
                </li>
              </ul>
            </div>
          </>
        )}

        <h3 className="sidebar-title">Recent Posts</h3>
        <div className="sidebar-item recent-posts">
          {blogs &&
            blogs.map(item => (
              <div className="post-item clearfix" key={item._id}>
                <img src={item.blogImage} alt="Blog Image" />
                <h4>
                  <Link
                    to={`/blogs/${item._id}`}
                    onClick={updateBlogItemViews(item._id)}
                  >
                    {item.blogTitle}
                  </Link>
                </h4>
                <time dateTime={item.insertDate}>
                  {item.insertDate.toString().substring(0, 10)}
                </time>
              </div>
            ))}
        </div>
        {location.pathname === '/blogs' && (
          <>
            <h3 className="sidebar-title">Tags</h3>
            <div className="sidebar-item tags">
              <ul>
                {tags.map((tag, index) => (
                  <li key={index}>
                    <a onClick={() => searchBlogsByTag(tag)}>{tag}</a>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default BlogSideBar;
