import * as actionTypes from "../constants/itemConstants";

export const getQueryItemsReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case actionTypes.GET_ITEMS_REQUEST:
      return {
        loading: true,
        items: [],
      };
    case actionTypes.GET_ITEMS_SUCCESS:
      return {
        loading: false,
        items: action.payload,
      };
    case actionTypes.GET_ITEMS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getQueryItemDetailsReducer = (state = { item: {} }, action) => {
  switch (action.type) {
    case actionTypes.GET_ITEM_DETAILS_REQUEST:
      return {
        loading: true,
        item: {},
      };
    case actionTypes.GET_ITEM_DETAILS_SUCCESS:
      return {
        loading: false,
        item: action.payload,
      };

    case actionTypes.GET_ITEM_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const postQueryItemReducer = (state = { item: {} }, action) => {
  switch (action.type) {
    case actionTypes.POST_ITEM_REQUEST:
      return {
        loading: true,
        item: {},
      };
    case actionTypes.POST_ITEM_SUCCESS:
      return {
        loading: false,
        item: action.payload,
      };
    case actionTypes.POST_ITEM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateQueryItemReducer = (state = { item: {} }, action) => {
  switch (action.type) {
    case actionTypes.PUT_ITEM_REQUEST:
      return {
        loading: true,
        item: {},
      };
    case actionTypes.PUT_ITEM_SUCCESS:
      return {
        loading: false,
        item: action.payload,
      };
    case actionTypes.PUT_ITEM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteQueryItemReducer = (state = { item: {} }, action) => {
  switch (action.type) {
    case actionTypes.DELETE_ITEM_REQUEST:
      return {
        loading: true,
        item: {},
      };
    case actionTypes.DELETE_ITEM_SUCCESS:
      return {
        loading: false,
        item: action.payload,
      };
    case actionTypes.DELETE_ITEM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
