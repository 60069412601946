import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  postPortfolioItem,
  getPortfolioItemDetails,
  updatePortfolioItem,
  deletePortfolioItem,
} from "../redux/actions/portfolioItemActions";
import { getCurrentUser } from "../redux/reducers/userReducers";
import { postUserImg } from "../redux/actions/userActions";
import Breadcrumbs from "../components/Breadcrumbs";
import Preloader from "../components/Preloader";
import NotFoundPage from "./NotFoundPage";
import "../css/adminPortfoliosEdit.css";

function AdminPortfoliosEdit() {
  const dispatch = useDispatch();
  const { id: portfolioId } = useParams();
  const user = getCurrentUser();
  const navigate = useNavigate();

  const [portfolio, setPortfolio] = useState({});
  const [clicked, setClicked] = useState(false);
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("");
  const [uploadedFile, setUploadedFile] = useState({});
  const [date, setDate] = useState("");
  const [img, setImg] = useState({});

  useEffect(() => {
    !user && navigate("/admin-entry");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (portfolioId === "new") return;
    dispatch(getPortfolioItemDetails(portfolioId));
  }, [portfolioId, dispatch]);

  const { loading, item, error } = useSelector(
    (state) => state.getPortfolioItemDetails
  );

  const { uploadPercentage, postedFile } = useSelector(
    (state) => state.postUserImg
  );

  // reform the the date to "YYYY-MM-DD"
  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      throw new Error('Invalid "date" argument. You must pass a date instance');
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    postedFile && setUploadedFile(postedFile);
  }, [postedFile]);

  // set portfolio data
  useEffect(() => {
    if (portfolioId === "new") return;
    if (!loading && !error) {
      setPortfolio(item);
      const newDate = new Date(item.projectDate);
      item.projectDate && setDate(formatDate(newDate));
    }
  }, [item, portfolioId, loading]);

  // handle portfolio text change
  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setPortfolio({ ...portfolio, [name]: value });
  };

  // handle portfolio array text change
  const handleArrayTextChange = (e, id) => {
    const { name, value } = e.target;
    const newImages = portfolio.images.map((item, index) => {
      if (index === id) {
        return { ...item, [name]: value };
      } else {
        return item;
      }
    });
    setPortfolio({ ...portfolio, images: newImages });
  };

  // handle local image path text change
  const handleImgTextChange = (e) => {
    const { name, value } = e.target;
    setImg({ [name]: value });
  };

  // handle add new images to portfolio array
  const handleAddImage = () => {
    if (!img.image) {
      toast.error("Image path required");
      return;
    }
    if (portfolio.images) {
      setPortfolio({
        ...portfolio,
        images: [...portfolio.images, img],
      });
      setImg({ image: "" });
    } else {
      setPortfolio({ ...portfolio, images: [img] });
      setImg({ image: "" });
    }
  };

  // handle select
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setPortfolio({ ...portfolio, [name]: value });
  };

  // handle check box
  const handleCheckChange = () => {
    setPortfolio({ ...portfolio, private: !portfolio.private });
  };

  // handle delete portforlio
  const handleDeletePortfolio = (id) => {
    if (portfolioId === "new") {
      toast.error("This portfolio has not been created yet");
      return;
    }
    dispatch(deletePortfolioItem(id));
  };

  // handle copy to clipboard
  const handleClipboardChange = (text) => {
    navigator.clipboard.writeText(text);
    setClicked(true);
  };

  // handle change date
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setPortfolio({ ...portfolio, [name]: value });
    setDate(value);
  };

  // Image change and upload
  const onImageChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  // Image upload
  const handleImgSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    dispatch(postUserImg(formData));
  };

  // handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (portfolioId === "new") {
      dispatch(postPortfolioItem(portfolio));
    } else {
      dispatch(updatePortfolioItem(portfolioId, portfolio));
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        <NotFoundPage />
      ) : (
        <>
          <Helmet>
            <title>DStudio Tech. | Admin | Portfolio Edit</title>
          </Helmet>
          <main id="main">
            <Breadcrumbs title="Edit Portfolio" />
            <section className="admin-portfolios-mgmt">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  >
                    <div className="admin-form">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-3">Edit Portfolio</h4>
                        <i
                          className="bi bi-trash3"
                          onClick={() => {
                            handleDeletePortfolio(portfolioId);
                          }}
                        ></i>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            name="name"
                            onChange={handleTextChange}
                            value={portfolio.name ? portfolio.name : ""}
                            type="text"
                            className="form-control"
                            placeholder="Portfolio Name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="title"
                            onChange={handleTextChange}
                            value={portfolio.title ? portfolio.title : ""}
                            type="text"
                            className="form-control"
                            placeholder="Portfolio Title"
                          />
                        </div>
                        <div className="row d-flex align-items-center">
                          <div className="form-group col-md-4">
                            <select
                              name="category"
                              className="form-select"
                              value={
                                portfolio.category ? portfolio.category : ""
                              }
                              onChange={handleSelectChange}
                            >
                              <option defaultValue>Choose Category</option>
                              <option value="Web Design">Web Design</option>
                              <option value="Web Development">
                                Web Development
                              </option>
                              <option value="App Development">
                                App Development
                              </option>
                              <option value="Graphic Design">
                                Graphic Design
                              </option>
                              <option value="Digital Marketing">
                                Digital Marketing
                              </option>
                              <option value="UX/UI Design">UX/UI Design</option>
                              <option value="Database">Database</option>
                              <option value="Data Analytics">
                                Data Analytics
                              </option>
                              <option value="IT Training">IT Training</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                          <div className="form-group col-md-4">
                            <select
                              name="filter"
                              className="form-select"
                              value={portfolio.filter ? portfolio.filter : ""}
                              onChange={handleSelectChange}
                            >
                              <option defaultValue>Choose Category</option>
                              <option value="filter-web">Web</option>
                              <option value="filter-uxui">Design</option>
                              <option value="filter-app">App</option>
                              <option value="filter-others">Others</option>
                            </select>
                          </div>
                          <div className="form-group form-check form-switch col-md-4">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={portfolio.private ? true : false}
                              onChange={handleCheckChange}
                            />
                            <label className="form-check-label ms-2">
                              {portfolio.private ? "Private" : "Public"}
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            name="projectUrl"
                            onChange={handleTextChange}
                            value={
                              portfolio.projectUrl ? portfolio.projectUrl : ""
                            }
                            type="text"
                            className="form-control"
                            placeholder="Portfolio URL"
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            name="description"
                            value={portfolio.description}
                            rows="5"
                            onChange={handleTextChange}
                            placeholder="Description"
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <h6>Upload Portfolio Images</h6>
                          <div className="input-group">
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              onChange={onImageChange}
                            />
                            <button
                              className="btn btn-primary"
                              type="submit"
                              onClick={handleImgSubmit}
                            >
                              Upload
                            </button>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: `${uploadPercentage}%` }}
                            >
                              {uploadPercentage}%
                            </div>
                          </div>
                        </div>
                        <div className="form-group clipboard">
                          <input
                            name="projectUrl"
                            value={
                              uploadedFile.filePath
                                ? uploadedFile.filePath
                                : "Image Path"
                            }
                            type="text"
                            className="form-control"
                            placeholder="Image Path"
                            disabled
                          />
                          <i
                            className={`bi ${
                              clicked ? "bi-clipboard-check" : "bi-clipboard"
                            }`}
                            onClick={() =>
                              handleClipboardChange(
                                uploadedFile.filePath
                                  ? uploadedFile.filePath
                                  : "Image Path"
                              )
                            }
                          ></i>
                        </div>
                        <div className="form-group">
                          <h6>Portfolio Thumnail</h6>
                          <input
                            name="thumnail"
                            onChange={handleTextChange}
                            value={portfolio.thumnail ? portfolio.thumnail : ""}
                            type="text"
                            className="form-control"
                            placeholder="Portfolio Thumnail"
                          />
                        </div>
                        <h6>Add Portfolio Swiper Images</h6>
                        <div className="form-group add-images">
                          <input
                            name="image"
                            onChange={handleImgTextChange}
                            value={img.image}
                            type="text"
                            className="form-control"
                            placeholder="Portfolio Thumnail"
                          />
                          <i
                            className="bi bi-plus-circle"
                            onClick={handleAddImage}
                          ></i>
                        </div>
                        {portfolio.images && <h6>Portfolio Swiper Images</h6>}
                        {portfolio.images &&
                          portfolio.images.map((item, index) => (
                            <div className="form-group" key={index}>
                              <input
                                name="image"
                                onChange={(e) =>
                                  handleArrayTextChange(e, index)
                                }
                                value={item.image ? item.image : ""}
                                type="text"
                                className="form-control"
                                placeholder="Portfolio Thumnail"
                              />
                            </div>
                          ))}
                        <div className="form-group">
                          <h6>Portfolio Date</h6>
                          <input
                            name="projectDate"
                            onChange={handleDateChange}
                            value={date}
                            type="date"
                            className="form-control"
                            placeholder="Portfolio Date"
                          />
                        </div>
                        <div className="d-flex justify-content-center">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    className="col-lg-6"
                    data-aos="fade-left"
                    data-aos-delay="100"
                  >
                    <div className="admin-form">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-3">Your Portfolio</h4>
                      </div>
                      <form>
                        <div className="form-group">
                          <input
                            name="name"
                            value={portfolio.name ? portfolio.name : ""}
                            type="text"
                            className="form-control"
                            placeholder="Portfolio Name"
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="title"
                            value={portfolio.title ? portfolio.title : ""}
                            type="text"
                            className="form-control"
                            placeholder="Portfolio Title"
                            disabled
                          />
                        </div>
                        <div className="row d-flex align-items-center">
                          <div className="form-group col-md-4">
                            <input
                              name="title"
                              value={
                                portfolio.category ? portfolio.category : ""
                              }
                              type="text"
                              className="form-control"
                              placeholder="Portfolio Title"
                              disabled
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <input
                              name="title"
                              value={portfolio.filter ? portfolio.filter : ""}
                              type="text"
                              className="form-control"
                              placeholder="Portfolio Title"
                              disabled
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <p>{portfolio.private ? "Private" : "Public"}</p>
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            name="projectUrl"
                            value={
                              portfolio.projectUrl ? portfolio.projectUrl : ""
                            }
                            type="text"
                            className="form-control"
                            placeholder="Portfolio URL"
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            name="description"
                            value={portfolio.description}
                            rows="5"
                            placeholder="Description"
                            disabled
                          ></textarea>
                        </div>

                        {portfolio.thumnail && (
                          <div className="form-group">
                            <h6>Portfolio Thumnail</h6>
                            <img
                              className="img-fluid"
                              src={portfolio.thumnail}
                              alt=""
                            />
                          </div>
                        )}

                        {portfolio.images && <h6>Portfolio Swiper Images</h6>}
                        <div className="row">
                          {portfolio.images &&
                            portfolio.images.map((item, index) => (
                              <div className="form-group col-md-4" key={index}>
                                <img
                                  className="img-fluid"
                                  src={item.image}
                                  alt="Image not loaded"
                                />
                              </div>
                            ))}
                        </div>
                        <div className="form-group">
                          <h6>Portfolio Date</h6>
                          <input
                            name="projectDate"
                            value={date}
                            type="date"
                            className="form-control"
                            placeholder="Portfolio Date"
                            disabled
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default AdminPortfoliosEdit;
