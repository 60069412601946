import React from "react";
import "../css/breadcrumbs.css";
import { Link } from "react-router-dom";

function Breadcrumbs({ title }) {
  return (
    <section className="breadcrumbs">
      <div className="container d-flex justify-content-between align-items-center">
        <div>
          <ol>
            <li>
              <Link to="/">
                <i className="bi bi-house-fill"></i>
              </Link>
            </li>
            <li>{title}</li>
          </ol>
          <h2>{title}</h2>
        </div>
      </div>
    </section>
  );
}

export default Breadcrumbs;
