import React from "react";

function ServicesSection({ web, textChange, dataChange }) {
  return (
    <div className="admin-form tab-pane fade show" id="services">
      <form>
        <h4 className="mb-3">Services Section</h4>
        <div className="form-group">
          <input
            name="title"
            onChange={textChange}
            value={web.services.title}
            type="text"
            className="form-control"
            placeholder="Title"
          />
        </div>
        <div className="form-group">
          <input
            name="subtitle"
            onChange={textChange}
            value={web.services.subtitle}
            type="text"
            className="form-control"
            placeholder="Subtitle"
          />
        </div>
        {web.services.data &&
          web.services.data.map((item, index) => (
            <div className="row" key={item._id}>
              <h6 className="mb-3">Item {index + 1}</h6>
              <div className="form-group">
                <input
                  name="title"
                  onChange={(e) => dataChange(e, item._id)}
                  value={item.title}
                  type="text"
                  className="form-control"
                  placeholder="Title"
                />
              </div>
              <div className="form-group">
                <input
                  name="description"
                  onChange={(e) => dataChange(e, item._id)}
                  value={item.description}
                  type="text"
                  className="form-control"
                  placeholder="Description"
                />
              </div>
              <div className="form-group">
                <input
                  name="delay"
                  onChange={(e) => dataChange(e, item._id)}
                  value={item.delay}
                  type="text"
                  className="form-control"
                  placeholder="Image URL"
                />
              </div>
              <div className="form-group">
                <input
                  name="color"
                  onChange={(e) => dataChange(e, item._id)}
                  value={item.color}
                  type="text"
                  className="form-control"
                  placeholder="Color"
                />
              </div>
            </div>
          ))}
      </form>
    </div>
  );
}

export default ServicesSection;
