const employmentData = [
  {
    _id: 1,
    title: `UI/UX designer`,
    icon: "bi bi-easel",
    description: `We need to hire a UI/UX designer who can create visually appealing and user-friendly interfaces for our digital products.`,
    link: ``,
  },
  {
    _id: 2,
    title: `Frontend Developer`,
    icon: "bi bi-laptop",
    description: `We are looking for a skilled frontend developer who can build and maintain the user-facing side of our web applications.`,
    link: ``,
  },
  {
    _id: 3,
    title: `Full Stack Developer`,
    icon: "bi bi-motherboard",
    description: `We are seeking a talented full stack developer who can design and implement scalable, efficient, and maintainable web applications.`,
    link: ``,
  },
  {
    _id: 4,
    title: `IT Consultant`,
    icon: "bi bi-chat-square-text",
    description: `We are looking for an IT consultant who can provide expert advice and guidance to our clients on IT solutions to achieve business goals.`,
    link: ``,
  },
  {
    _id: 5,
    title: `Data Analyst`,
    icon: "bi bi-clipboard-data",
    description: `We need to hire data analyst who can collect, process, and perform statistical analyses to help drive decision-making for our clients.`,
    link: ``,
  },
  {
    _id: 6,
    title: `Internship Program`,
    icon: "bi bi-calendar4-week",
    description: `Our internship program offers hands-on experience and mentorship to equip you with the skills necessary to succeed in the industry`,
    link: ``,
  },
];

export default employmentData;
