import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTrainingItemDetails } from '../redux/actions/trainingItemActions';
import Breadcrumbs from '../components/Breadcrumbs';
import Preloader from '../components/Preloader';
import { toast } from 'react-toastify';

function TrainingSinglePage() {
  const dispatch = useDispatch();
  const { id: trainingId } = useParams();
  const [training, setTraining] = useState({});

  useEffect(() => {
    dispatch(getTrainingItemDetails(trainingId));
  }, [trainingId, dispatch]);

  const { loading, item, error } = useSelector(
    state => state.getTrainingItemDetails
  );

  // set training item data
  useEffect(() => {
    if (!loading && !error) {
      setTraining(item);
    }
  }, [item, loading, error]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <>
          <Helmet>
            {training.title && (
              <title>DStudio Tech. | Course | {`${training.title}`}</title>
            )}
            {training.description && (
              <meta name="description" content={`${training.description}`} />
            )}
          </Helmet>
          <main id="main">
            <Breadcrumbs title={`Course - ${training.name}`} />
            <section id="portfolio-details" className="portfolio-details">
              <div className="container">
                <div className="row gy-4">
                  <div className="col-lg-8">
                    <div className="portfolio-details-slider swiper-container">
                      <h3>Course Video</h3>
                      {training.video && (
                        <div className="ratio ratio-16x9">
                          <iframe src={training.video} allowFullScreen></iframe>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="portfolio-info">
                      <h3>Course information</h3>
                      <ul>
                        <li>
                          <strong>Name</strong>: {training.name}
                        </li>
                        <li>
                          <strong>Category</strong>: {training.category}
                        </li>
                        <li>
                          <strong>Published date</strong>:{' '}
                          {training.insertDate &&
                            training.insertDate.substring(0, 10)}
                        </li>
                        <li>
                          <strong>Video Link</strong>:{' '}
                          <a target="_blank" href={training.link}>
                            Check this out
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="portfolio-description">
                      <h2>{training.title}</h2>
                      <p>{training.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default TrainingSinglePage;
