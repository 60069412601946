import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import "../css/about.css";
import { EnvContext } from "../App";

function About() {
  const { scroll, sectionRefs: refs } = useContext(EnvContext);

  const [data, setData] = useState({});

  const { loading, item } = useSelector((state) => state.getWebItemDetails);

  useEffect(() => {
    if (!loading) setData(item.about);
  }, [loading, item]);

  const handlePageScroll = (section) => (e) => {
    e.preventDefault();
    if (scroll === 0) {
      window.scrollTo(0, refs[section].current.offsetTop + 1);
    } else {
      window.scrollTo(
        0,
        refs[section].current.offsetTop - refs.header.current.offsetHeight + 1
      );
    }
  };
  return (
    <section id="about" className="about" ref={refs.about}>
      {data && (
        <div className="container" data-aos="fade-up">
          <div className="row gx-0">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="content">
                <h3>{data.subtitle}</h3>
                <h2>{data.title}</h2>
                <p>{data.description}</p>
                <div className="text-center text-lg-start">
                  <a
                    onClick={handlePageScroll("value")}
                    className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Read More</span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="col-lg-6 d-flex align-items-center"
              data-aos="zoom-out"
              data-aos-delay="200"
            >
              <img src={data.image} className="img-fluid" alt="About Image" />
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default About;
