import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getBlogItems } from "../redux/actions/blogItemActions";
import { updateBlogItemViews } from "../redux/actions/blogItemActions";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../css/blogs.css";
import { EnvContext } from "../App";
import Preloader from "./Preloader";

function Blogs() {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [sortColumn, setSortColumn] = useState({
    path: "insertDate",
    order: "desc",
  });

  const { sectionRefs: refs } = useContext(EnvContext);

  useEffect(() => {
    async function fetchBlogs() {
      await dispatch(getBlogItems());
    }
    fetchBlogs();
  }, [dispatch]);

  const blogItems = useSelector((state) => state.getBlogItems);
  const { loading, items, error } = blogItems;

  useEffect(() => {
    const sortedItems = _.orderBy(items, [sortColumn.path], [sortColumn.order]);
    setData(sortedItems.slice(0, 3));
  }, [items, sortColumn]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <section
          id="recent-blog-posts"
          className="recent-blog-posts"
          ref={refs.blogs}
        >
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>Blog</h2>
              <p>Recent posts form our Blog</p>
            </header>
            {data && (
              <div className="row">
                {data.map((item) => (
                  <div className="col-lg-4" key={item._id}>
                    <div className="post-box">
                      <div className="post-img">
                        <img
                          src={item.blogImage}
                          className="img-fluid"
                          alt="Blog Image"
                        />
                      </div>
                      <span className="post-date">
                        {item.insertDate.toString().substring(0, 10)}
                      </span>
                      <h3 className="post-title">
                        {item.blogTitle.toString().substring(0, 80)}...
                      </h3>
                      <Link
                        to={`/blogs/${item._id}`}
                        onClick={updateBlogItemViews(item._id)}
                        className="readmore stretched-link mt-auto"
                      >
                        <span>Read More</span>
                        <i className="bi bi-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}

export default Blogs;
