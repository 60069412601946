import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../redux/reducers/userReducers";
import {
  deleteNewsletterItem,
  getNewsletterItems,
} from "../redux/actions/newsletterItemActions";
import Breadcrumbs from "../components/Breadcrumbs";
import Preloader from "../components/Preloader";
import { toast } from "react-toastify";

function AdminNewsletterMgmt() {
  const user = getCurrentUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [newsletters, setNewsletters] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    user ? dispatch(getNewsletterItems()) : navigate("/admin-entry");
  }, [dispatch]);

  const { loading, items, error } = useSelector(
    (state) => state.getNewsletterItems
  );

  useEffect(() => {
    setNewsletters(items);
  }, [items]);

  const handleDeleteNewsletter = (id) => {
    let data = newsletters.filter((item) => item._id !== id);
    dispatch(deleteNewsletterItem(id));
    setNewsletters(data);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <>
          <Helmet>
            <title>DStudio Tech. | Admin | Newsletter Mgmt.</title>
          </Helmet>
          <main id="main">
            <Breadcrumbs title="Newsletter Subscriptions" />
            <section className="newsletter-mgmt">
              <div className="container">
                <header
                  className="section-header"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <h2>Newsletter Subsriptions</h2>
                  <div className="d-flex justify-content-center align-items-center new-portfolio">
                    <p>Subscriber details</p>
                  </div>
                </header>
                <div
                  className="row d-flex justify-content-center table-responsive"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="col-lg-10">
                    {newsletters.length === 0 ? (
                      <h6 className="text-center">
                        You have no newsletter subscribers
                      </h6>
                    ) : (
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Subscriber Email</th>
                            <th scope="col">Date & Time</th>
                            <th scope="col" className="text-center">
                              Delete
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {newsletters.map((item, index) => (
                            <tr key={item._id}>
                              <th scope="row">{index + 1}</th>
                              <td>{item.email}</td>
                              <td>{item.insertDate}</td>
                              <td className="text-center">
                                <i
                                  className="bi bi-trash"
                                  onClick={() =>
                                    handleDeleteNewsletter(item._id)
                                  }
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default AdminNewsletterMgmt;
