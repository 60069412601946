import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Glightbox from "glightbox";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getCurrentUser } from "../redux/reducers/userReducers";
import { getPortfolioItems } from "../redux/actions/portfolioItemActions";
import Breadcrumbs from "../components/Breadcrumbs";
import Preloader from "../components/Preloader";
import { toast } from "react-toastify";
import "../css/adminPortfoliosMgmt.css";

function AdminPortfoliosMgmt() {
  new Glightbox({
    selector: ".portfolio-mgmt-lightbox",
  });

  const user = getCurrentUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !user && navigate("/admin-entry");
  }, []);

  useEffect(() => {
    dispatch(getPortfolioItems());
  }, [dispatch]);

  const { loading, items, error } = useSelector(
    (state) => state.getPortfolioItems
  );

  useEffect(() => {
    setPortfolios(items);
  }, [items]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <>
          <Helmet>
            <title>DStudio Tech. | Admin | Portfolio Mgmt.</title>
          </Helmet>
          <main id="main">
            <Breadcrumbs title="Portfolio Management" />
            <section className="portfolios-mgmt">
              <div className="container">
                <header
                  className="section-header"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <h2>Portfolio</h2>
                  <div className="d-flex justify-content-center align-items-center new-portfolio">
                    <p>Your Portfolio</p>
                    <Link to="/admin-portfolios-mgmt/new">
                      <i className="bi bi-plus-circle"></i>
                    </Link>
                  </div>
                </header>
                <div
                  className="row portfolio-container"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  {portfolios.map((item) => (
                    <div
                      key={item._id}
                      className={`col-lg-3 col-md-6 mb-4 portfolio-item ${item.filter}`}
                    >
                      <div className="portfolio-wrap">
                        <img
                          src={item.thumnail}
                          className="img-fluid"
                          alt={item.name}
                        />
                        <div className="portfolio-info">
                          <h4>{item.name}</h4>
                          <p>{item.title}</p>
                          <div className="portfolio-links">
                            <a
                              className="portfolio-mgmt-lightbox"
                              href={item.thumnail}
                              data-gallery="portfolioGallery"
                              title={item.name}
                            >
                              <i className="bi bi-plus"></i>
                            </a>
                            <Link
                              to={`/admin-portfolios-mgmt/${item._id}`}
                              title="More Details"
                            >
                              <i className="bi bi-link"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default AdminPortfoliosMgmt;
