import React, { useEffect, useState, useContext } from 'react';
import Glightbox from 'glightbox';
import { useSelector } from 'react-redux';
import '../css/hero.css';
import { EnvContext } from '../App';
import Preloader from './Preloader';
import { toast } from 'react-toastify';

function Hero() {
  const { scroll, sectionRefs: refs } = useContext(EnvContext);

  const [data, setData] = useState({});

  const { loading, item, error } = useSelector(
    state => state.getWebItemDetails
  );

  useEffect(() => {
    if (!loading) {
      setData(item.hero);
      new Glightbox({
        selector: '.glightbox',
      });
    }
  }, [loading, item]);

  const handlePageScroll = section => e => {
    e.preventDefault();
    if (scroll === 0) {
      window.scrollTo(0, refs[section].current.offsetTop + 1);
    } else {
      window.scrollTo(
        0,
        refs[section].current.offsetTop - refs.header.current.offsetHeight + 1
      );
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <section
          id="hero"
          ref={refs.hero}
          className={`hero d-flex align-items-center ${
            scroll > 100 ? 'sticked-header-offset' : undefined
          }`}
        >
          {data && (
            <div className="container">
              <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <h1 data-aos="fade-up">{data.title}</h1>
                  <h2 data-aos="fade-up" data-aos-delay="400">
                    {data.subtitle}
                  </h2>
                  <div data-aos="fade-up" data-aos-delay="600">
                    <div className="d-flex justify-content-center justify-content-lg-start">
                      <a
                        onClick={handlePageScroll('about')}
                        className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                      >
                        <span>Get Started</span>
                        <i className="bi bi-arrow-right"></i>
                      </a>
                      <a
                        className="glightbox btn-watch-video d-flex align-items-center"
                        href={data.video}
                        target="_blank"
                      >
                        <i className="bi bi-play-circle"></i>
                        <span>Watch Video</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 hero-img"
                  data-aos="zoom-out"
                  data-aos-delay="200"
                >
                  <img
                    src={data.image}
                    className="img-fluid"
                    alt="Hero Image"
                  />
                </div>
              </div>
            </div>
          )}
        </section>
      )}
    </>
  );
}

export default Hero;
