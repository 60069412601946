import React, { useEffect, useState, useRef, useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolioItems } from "../redux/actions/portfolioItemActions";
import "../css/portfolio.css";
import { Link } from "react-router-dom";
import Isotope from "isotope-layout";
import Glightbox from "glightbox";
import { EnvContext } from "../App";
import { filterData } from "../data/portfolioData";
import Preloader from "./Preloader";
import { toast } from "react-toastify";

function Portfolio() {
  new Glightbox({
    selector: ".portfolio-lightbox",
  });

  const dispatch = useDispatch();
  const { sectionRefs: refs } = useContext(EnvContext);
  const isotope = useRef();
  const [filterKey, setFilterKey] = useState("*");
  const [filter, setFilter] = useState(filterData);
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getPortfolioItems());
  }, [dispatch]);

  const { loading, items, error } = useSelector(
    (state) => state.getPortfolioItems
  );

  useEffect(() => {
    setData(items);
  }, [items]);

  useEffect(() => {
    if (!loading && data) {
      isotope.current = new Isotope(".portfolio-container", {
        itemSelector: ".portfolio-item",
        layoutMode: "masonry",
      });
    }
    // cleanup
    return () => (isotope.current = null);
  }, [loading, data]);

  useEffect(() => {
    filterKey === "*"
      ? isotope.current.arrange({ filter: `*` })
      : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const handleFilterKeyChange = (key, id) => {
    setFilterKey(key);
    const newFilter = filter.map((item) => {
      item.active = false;
      if (item._id === id) item.active = true;
      return item;
    });
    setFilter(newFilter);
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <section id="portfolio" className="portfolio" ref={refs.portfolio}>
          <div className="container" data-aos="fade-up">
            <header className="section-header">
              <h2>Portfolio</h2>
              <p>Check our latest work</p>
            </header>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-12 d-flex justify-content-center">
                <ul id="portfolio-flters">
                  {filter.map((item) => (
                    <li
                      key={item._id}
                      data-filter={item.filter}
                      className={item.active ? "filter-active" : undefined}
                      onClick={() =>
                        handleFilterKeyChange(item.filter, item._id)
                      }
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div
              className="row gy-4 portfolio-container"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {data.map((item) => (
                <div
                  key={item._id}
                  className={`col-lg-4 col-md-6 portfolio-item ${item.filter}`}
                >
                  <div className="portfolio-wrap">
                    <LazyLoadImage
                      src={item.thumnail}
                      className="img-fluid"
                      alt={item.name}
                      effect="blur"
                      afterLoad={() => isotope.current.arrange({ filter: "*" })}
                    />
                    <div className="portfolio-info">
                      <h4>{item.name}</h4>
                      <p>{item.title}</p>
                      <div className="portfolio-links">
                        <a
                          href={item.thumnail}
                          data-gallery="portfolioGallery"
                          className="portfolio-lightbox"
                          title={item.name}
                        >
                          <i className="bi bi-plus"></i>
                        </a>
                        <Link
                          to={`/portfolio/${item._id}`}
                          title="More Details"
                        >
                          <i className="bi bi-link"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Portfolio;
