import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../redux/reducers/userReducers";
import {
  deleteQueryItem,
  getQueryItemDetails,
  getQueryItems,
  updateQueryItem,
} from "../redux/actions/queryItemActions";
import Breadcrumbs from "../components/Breadcrumbs";
import Preloader from "../components/Preloader";
import { toast } from "react-toastify";
import Modal from "../components/Modal";
import "../css/adminQueriesMgmt.css";

function AdminQueriesMgmt() {
  const user = getCurrentUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [queries, setQueries] = useState([]);
  const [modalId, setModalId] = useState("");
  const [query, setQuery] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    user ? dispatch(getQueryItems()) : navigate("/admin-entry");
  }, [dispatch]);

  const { loading, items, error } = useSelector((state) => state.getQueryItems);
  const { item } = useSelector((state) => state.getQueryItemDetails);

  useEffect(() => {
    setQueries(items);
  }, [items]);

  useEffect(() => {
    setQuery(item);
  }, [item]);

  const handleToggleReply = (id) => {
    let data = queries.map((item) => {
      if (item._id === id) {
        let newItem = { ...item, replied: !item.replied };
        dispatch(updateQueryItem(item._id, newItem));
        return newItem;
      } else {
        return item;
      }
    });
    setQueries(data);
  };

  const handleDeleteQuery = (id) => {
    let data = queries.filter((item) => item._id !== id);
    dispatch(deleteQueryItem(id));
    setQueries(data);
  };

  const handleViewQuery = (id) => {
    dispatch(getQueryItemDetails(id));
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        toast.error(error)
      ) : (
        <>
          <Helmet>
            <title>DStudio Tech. | Admin | Queries Mgmt.</title>
          </Helmet>
          <main id="main">
            <Breadcrumbs title="Queries Management" />
            <section className="queries-mgmt">
              <div className="container">
                <header
                  className="section-header"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <h2>Queries</h2>
                  <div className="d-flex justify-content-center align-items-center new-portfolio">
                    <p>Clients Queries</p>
                  </div>
                </header>
                <div
                  className="row table-responsive"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  {queries.length === 0 ? (
                    <h6 className="text-center">You have no client queries</h6>
                  ) : (
                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Title</th>
                          <th scope="col">Client</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Company</th>
                          <th scope="col">Size</th>
                          <th scope="col">Services</th>
                          <th scope="col" className="text-center">
                            Replied
                          </th>
                          <th scope="col" className="text-center">
                            View
                          </th>
                          <th scope="col" className="text-center">
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {queries.map((item, index) => (
                          <tr key={item._id}>
                            <th scope="row">{index + 1}</th>
                            <td>{item.title}</td>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>
                            <td>{item.business}</td>
                            <td>{item.size}</td>
                            <td>{item.service}</td>
                            <td className="text-center">
                              {item.replied ? (
                                <i
                                  className="bi bi-check-circle-fill"
                                  onClick={() => handleToggleReply(item._id)}
                                ></i>
                              ) : (
                                <i
                                  className="bi bi-check-circle"
                                  onClick={() => handleToggleReply(item._id)}
                                ></i>
                              )}
                            </td>
                            <td className="text-center">
                              <i
                                className="bi bi-box-arrow-in-right"
                                data-bs-toggle="modal"
                                data-bs-target="#queryViewModal"
                                onClick={() => handleViewQuery(item._id)}
                              ></i>
                            </td>
                            <td className="text-center">
                              <i
                                className="bi bi-trash"
                                data-bs-toggle="modal"
                                data-bs-target="#queryDeleteModal"
                                onClick={() => setModalId(item._id)}
                              ></i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
              <Modal
                handleQueryAction={handleDeleteQuery}
                id={modalId}
                name="queryDeleteModal"
                title="Warning"
                body="Are you sure you want to delete this query?"
              />
              <Modal
                id={modalId}
                name="queryViewModal"
                title="Query Details"
                body={query}
              />
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default AdminQueriesMgmt;
