import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import "../css/value.css";
import { EnvContext } from "../App";

function Value() {
  const { sectionRefs } = useContext(EnvContext);
  const [data, setData] = useState({});
  const { loading, item } = useSelector((state) => state.getWebItemDetails);

  useEffect(() => {
    if (!loading) setData(item.value);
  }, [loading, item]);

  return (
    <section id="values" className="values" ref={sectionRefs.value}>
      {data && (
        <div className="container" data-aos="fade-up">
          <header className="section-header">
            <h2>{data.subtitle}</h2>
            <p>{data.title}</p>
          </header>

          <div className="row">
            {data.data &&
              data.data.map((item) => (
                <div
                  className={`col-lg-4 ${item._id > 1 && "mt-4 mt-lg-0"}`}
                  key={item._id}
                >
                  <div
                    className="box"
                    data-aos="fade-up"
                    data-aos-delay={item.delay}
                  >
                    <img src={item.imgUrl} className="img-fluid" alt="" />
                    <h3>{item.title}</h3>
                    <p>{item.content}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </section>
  );
}

export default Value;
