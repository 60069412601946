import React, { useState, useEffect } from "react";
import "../css/adminForm.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postUser } from "../redux/actions/userActions";
import { getCurrentUser } from "../redux/reducers/userReducers";
import ShowPwsIcon from "./ShowPwsIcon";

function AdminLoginForm({ changeForm }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.loginUser);
  const [checkBox, setCheckBox] = useState(false);
  const [showPws, setShowPws] = useState(false);
  const user = getCurrentUser();

  useEffect(() => {
    user && navigate("/admin-dashboard");
  }, []);

  useEffect(() => {
    if (localStorage.getItem("email") && localStorage.getItem("password")) {
      setCheckBox(true);
    }
  }, []);

  const initialState = {
    email: localStorage.getItem("email") || "",
    password: localStorage.getItem("password") || "",
  };

  const [text, setText] = useState(initialState);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value });
  };

  const toggleShowPws = () => {
    setShowPws(!showPws);
  };

  const toggleCheckBox = () => {
    if (!checkBox) {
      localStorage.setItem("email", text.email);
      localStorage.setItem("password", text.password);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    setCheckBox(!checkBox);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(postUser(text));
    localStorage.getItem("token") && navigate("/admin-dashboard");
  };

  return (
    <div className="admin-form">
      <h4>Admin Login</h4>
      <p>
        Your email address will not be published. Required fields are marked *{" "}
      </p>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border login-spinner" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col form-group">
              <input
                type="email"
                className="form-control"
                name="email"
                value={text.email}
                placeholder="Your Email*"
                onChange={handleTextChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col form-group password">
              <input
                type={`${showPws ? "text" : "password"}`}
                name="password"
                className="form-control"
                value={text.password}
                placeholder="Your Password*"
                onChange={handleTextChange}
              />
              <ShowPwsIcon toggleShowPws={toggleShowPws} showPws={showPws} />
            </div>
          </div>
          <div className="row">
            <div className="col form-group">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={toggleCheckBox}
                  checked={checkBox}
                />
                <label className="form-check-label">Remember me</label>
              </div>
            </div>
          </div>
          {error ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : null}
          <button type="submit" className="btn btn-primary">
            Login
          </button>
        </form>
      )}

      <div className="row">
        <Link className="change-form-link" onClick={changeForm}>
          Admin Register <i className="bi bi-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
}

export default AdminLoginForm;
