import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import {
  getBlogItemsReducer,
  getBlogItemDetailsReducer,
  postBlogItemReducer,
  updateBlogItemReducer,
  deleteBlogItemReducer,
} from "./reducers/blogItemReducers";
import {
  getWebItemsReducer,
  getWebItemDetailsReducer,
  postWebItemReducer,
  updateWebItemReducer,
} from "./reducers/webItemReducers";
import {
  postUserReducer,
  createUserReducer,
  updateUserReducer,
  postUserImgReducer,
  getUserDetailsReducer,
  getUserImgUrlReducer,
} from "./reducers/userReducers";
import {
  getPortfolioItemsReducer,
  getPortfolioItemDetailsReducer,
  postPortfolioItemReducer,
  updatePortfolioItemReducer,
  deletePortfolioItemReducer,
} from "./reducers/portfolioItemReducers";
import {
  getQueryItemsReducer,
  getQueryItemDetailsReducer,
  postQueryItemReducer,
  updateQueryItemReducer,
  deleteQueryItemReducer,
} from "./reducers/queryItemReducers";
import {
  getNewsletterItemsReducer,
  getNewsletterItemDetailsReducer,
  postNewsletterItemReducer,
  updateNewsletterItemReducer,
  deleteNewsletterItemReducer,
} from "./reducers/newsletterItemReducers";
import {
  getTrainingItemsReducer,
  getTrainingItemDetailsReducer,
  postTrainingItemReducer,
  updateTrainingItemReducer,
  deleteTrainingItemReducer,
} from "./reducers/trainingItemReducers";

const reducer = combineReducers({
  getBlogItems: getBlogItemsReducer,
  getBlogItemDetails: getBlogItemDetailsReducer,
  postBlogItem: postBlogItemReducer,
  updateBlogItem: updateBlogItemReducer,
  deleteBlogItem: deleteBlogItemReducer,
  getPortfolioItems: getPortfolioItemsReducer,
  getPortfolioItemDetails: getPortfolioItemDetailsReducer,
  postPortfolioItem: postPortfolioItemReducer,
  updatePortfolioItem: updatePortfolioItemReducer,
  deletePortfolioItem: deletePortfolioItemReducer,
  loginUser: postUserReducer,
  registerUser: createUserReducer,
  postUserImg: postUserImgReducer,
  getUserDetails: getUserDetailsReducer,
  updateUser: updateUserReducer,
  getUserImgUrl: getUserImgUrlReducer,
  getWebItems: getWebItemsReducer,
  getWebItemDetails: getWebItemDetailsReducer,
  postWebItem: postWebItemReducer,
  updateWebItem: updateWebItemReducer,
  getQueryItems: getQueryItemsReducer,
  getQueryItemDetails: getQueryItemDetailsReducer,
  postQueryItem: postQueryItemReducer,
  updateQueryItem: updateQueryItemReducer,
  deleteQueryItem: deleteQueryItemReducer,
  getNewsletterItems: getNewsletterItemsReducer,
  getNewsletterItemDetails: getNewsletterItemDetailsReducer,
  postNewsletterItem: postNewsletterItemReducer,
  updateNewsletterItem: updateNewsletterItemReducer,
  deleteNewsletterItem: deleteNewsletterItemReducer,
  getTrainingItems: getTrainingItemsReducer,
  getTrainingItemDetails: getTrainingItemDetailsReducer,
  postTrainingItem: postTrainingItemReducer,
  updateTrainingItem: updateTrainingItemReducer,
  deleteTrainingItem: deleteTrainingItemReducer,
});

const middleware = [thunk];

const store = configureStore({
  reducer,
  middleware: middleware,
});

export default store;
