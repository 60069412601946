import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { postQueryItem } from "../redux/actions/queryItemActions";
import Breadcrumbs from "../components/Breadcrumbs";
import "../css/quotePage.css";

function QuotePage() {
  const dispatch = useDispatch();

  const initialQuote = {
    title: "",
    name: "",
    position: "",
    email: "",
    phone: "",
    business: "",
    industry: "",
    size: "",
    service: "",
    message: "",
  };

  const [quote, setQuote] = useState(initialQuote);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTextChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setQuote({ ...quote, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(postQueryItem(quote));
    setQuote(initialQuote);
  };

  return (
    <>
      <Helmet>
        <title>DStudio Tech. | Quotation & Queries</title>
      </Helmet>
      <main id="main">
        <Breadcrumbs title="Get quote" />
        <section className="quotation">
          <div className="container">
            <header
              className="section-header"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h2>Quotation</h2>
              <p>Get a quote for your business IT solution</p>
            </header>
            <div className="row justify-content-center">
              <div
                className="col-lg-10"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="quotation-form">
                  <form onSubmit={handleSubmit}>
                    <h5>Your Details</h5>
                    <div className="row">
                      <div className="form-group col-md-2">
                        <select
                          className="form-select"
                          name="title"
                          onChange={handleTextChange}
                        >
                          <option defaultValue>Title</option>
                          <option value="Miss">Miss</option>
                          <option value="Mrs">Mrs</option>
                          <option value="Ms">Ms</option>
                          <option value="Mr">Mr</option>
                          <option value="Dr">Dr</option>
                          <option value="Professor">Professor</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                      <div className="form-group col-md-5">
                        <input
                          name="name"
                          onChange={handleTextChange}
                          value={quote.name}
                          type="text"
                          className="form-control"
                          placeholder="Your Name"
                        />
                      </div>
                      <div className="form-group col-md-5">
                        <input
                          name="position"
                          onChange={handleTextChange}
                          value={quote.position}
                          type="text"
                          className="form-control"
                          placeholder="Your Occupation Position"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <input
                          name="email"
                          onChange={handleTextChange}
                          value={quote.email}
                          type="email"
                          className="form-control"
                          placeholder="Your Email"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          name="phone"
                          onChange={handleTextChange}
                          value={quote.phone}
                          type="text"
                          className="form-control"
                          placeholder="Your Phone Number"
                        />
                      </div>
                    </div>
                    <h5>Your Business Details</h5>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <input
                          name="business"
                          onChange={handleTextChange}
                          value={quote.business}
                          type="text"
                          className="form-control"
                          placeholder="Your Business Name"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          name="industry"
                          onChange={handleTextChange}
                          value={quote.industry}
                          type="text"
                          className="form-control"
                          placeholder="Your Business Industry"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <select
                          className="form-select"
                          name="size"
                          onChange={handleTextChange}
                        >
                          <option defaultValue>Size of your business</option>
                          <option value="Large">
                            Large (employees &ge; 50)
                          </option>
                          <option value="Medium">
                            Medium ( 15 &le; employees &lt; 50)
                          </option>
                          <option value="Small">
                            Small ( 5 &lt; employees &lt; 15)
                          </option>
                          <option value="Start up">
                            Start up (employees &le; 5)
                          </option>
                        </select>
                      </div>
                      <div className="form-group col-md-6">
                        <select
                          className="form-select"
                          name="service"
                          onChange={handleTextChange}
                        >
                          <option defaultValue>Choose required services</option>
                          <option value="Graphic Design">
                            Graphic Design (e.g. posters & flyers)
                          </option>
                          <option value="Web">Business/personal website</option>
                          <option value="App">Mobile App</option>
                          <option value="Web and App">
                            Website and mobile app
                          </option>
                          <option value="BIS">
                            Business information system
                          </option>
                          <option value="Database">
                            Database (e.g. store, query clients data)
                          </option>
                          <option value="Data Analytics">Data Analytics</option>
                          <option value="Digital Marketing">
                            Digital marketing (e.g. Newsletter)
                          </option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>
                    <h5>Your Message</h5>
                    <div className="row">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="message"
                          rows="8"
                          onChange={handleTextChange}
                          value={quote.message}
                          placeholder="Please leave a detailed message for us to better support you (e.g. detailed services you need, target to approach ,budget on mind, upgrading an existing information system, a fresh new applicaiton)..."
                        ></textarea>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default QuotePage;
