import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Breadcrumbs from '../components/Breadcrumbs';
import '../css/employmentPage.css';
import employmentData from '../data/employmentData';

function EmpolymentPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>DStudio Tech. | Employment</title>
      </Helmet>
      <main id="main">
        <Breadcrumbs title="Employment" />
        <section id="employment" className="employment sections-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <h2>Employment</h2>
              <p>Join our tech revolution and build your career with us</p>
            </div>

            <div className="row gy-4" data-aos="fade-up" data-aos-delay="100">
              {employmentData.map(item => (
                <div className="col-lg-4 col-md-6" key={item._id}>
                  <div className="employment-item  position-relative">
                    <div className="icon">
                      <i className={item.icon}></i>
                    </div>
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <a
                      href={`mailto:webmail.dstudio@gmail.com?subject=Application for ${item.title} at DStudio Tech.`}
                      rel="noopener"
                      target="_blank"
                      className="readmore stretched-link"
                    >
                      Apply now <i className="bi bi-arrow-right"></i>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default EmpolymentPage;
