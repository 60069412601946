import React from "react";

function PricingSection({
  web,
  textChange,
  dataChange,
  dataFeaturedChange,
  dataPriceListChange,
  dataPriceAvailableChange,
}) {
  return (
    <div className="admin-form tab-pane fade show" id="pricing">
      <form>
        <h4 className="mb-3">Pricing Section</h4>
        <div className="form-group">
          <input
            name="title"
            onChange={textChange}
            value={web.pricing.title}
            type="text"
            className="form-control"
            placeholder="Title"
          />
        </div>
        <div className="form-group">
          <input
            name="subtitle"
            onChange={textChange}
            value={web.pricing.subtitle}
            type="text"
            className="form-control"
            placeholder="Subtitle"
          />
        </div>
        {web.pricing.data &&
          web.pricing.data.map((item, index) => (
            <div className="row" key={item._id}>
              <h6 className="mb-3">Item {index + 1}</h6>
              <div className="form-group">
                <input
                  name="name"
                  onChange={(e) => dataChange(e, item._id)}
                  value={item.name}
                  type="text"
                  className="form-control"
                  placeholder="Name"
                />
              </div>
              <h6 className="mb-3">Item {index + 1} Price List</h6>
              {item.list.map((el) => (
                <div className="row d-flex align-items-center" key={el._id}>
                  <div className="form-group col-md-6">
                    <input
                      name="name"
                      onChange={(e) => dataPriceListChange(e, item._id, el._id)}
                      value={el.name}
                      type="text"
                      className="form-control"
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group form-check form-switch col-md-6">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={el.na ? true : false}
                      onChange={() =>
                        dataPriceAvailableChange(item._id, el._id)
                      }
                    />
                    <label className="form-check-label ms-2">
                      {el.na ? "N/A" : "Available"}
                    </label>
                  </div>
                </div>
              ))}
              <div className="form-group">
                <input
                  name="price"
                  onChange={(e) => dataChange(e, item._id)}
                  value={item.price}
                  type="text"
                  className="form-control"
                  placeholder="Price"
                />
              </div>
              <div className="form-group">
                <input
                  name="imgUrl"
                  onChange={(e) => dataChange(e, item._id)}
                  value={item.imgUrl}
                  type="text"
                  className="form-control"
                  placeholder="Image URL"
                />
              </div>
              <div className="form-group">
                <input
                  name="delay"
                  onChange={(e) => dataChange(e, item._id)}
                  value={item.delay}
                  type="text"
                  className="form-control"
                  placeholder="Image URL"
                />
              </div>
              <div className="row d-flex align-items-center">
                <div className="form-group col-md-6">
                  <input
                    name="color"
                    onChange={(e) => dataChange(e, item._id)}
                    value={item.color}
                    type="text"
                    className="form-control"
                    placeholder="Color"
                  />
                </div>
                <div className="form-group form-check form-switch col-md-6">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={item.featured ? true : false}
                    onChange={() => dataFeaturedChange(item._id)}
                  />
                  <label className="form-check-label ms-2">
                    {item.featured ? "Featured" : "Not featured"}
                  </label>
                </div>
              </div>
            </div>
          ))}
      </form>
    </div>
  );
}

export default PricingSection;
