import * as actionTypes from "../constants/userConstants";
import axios from "axios";
import { toast } from "react-toastify";
import host from "../constants/hostConstant";

export const postUser =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.POST_USER_REQUEST });
    try {
      const { data: jwt } = await axios.post(`${host}/api/auth`, {
        email,
        password,
      });
      dispatch({ type: actionTypes.POST_USER_SUCCESS, payload: jwt });
      toast(`Welcome to User Dashboard`);
    } catch (error) {
      dispatch({
        type: actionTypes.POST_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createUser =
  ({ name, email, password }) =>
  async (dispatch) => {
    dispatch({ type: actionTypes.CREATE_USER_REQUEST });
    try {
      const response = await axios.post(`${host}/api/users`, {
        name,
        email,
        password,
        isAdmin: true,
      });
      dispatch({ type: actionTypes.CREATE_USER_SUCCESS, payload: response });
      toast(`Account has been created, pending to be activated by supervisor`);
    } catch (error) {
      dispatch({
        type: actionTypes.CREATE_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      toast.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      );
    }
  };

export const updateUser = (user) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_USER_REQUEST });
  try {
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.put(`${host}/api/users/me`, user);
    dispatch({ type: actionTypes.UPDATE_USER_SUCCESS, payload: data });
    toast("Your Details have been updated");
  } catch (error) {
    dispatch({
      type: actionTypes.UPDATE_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const activateUser = (token) => async (dispatch) => {
  dispatch({ type: actionTypes.POST_USER_REQUEST });
  try {
    axios.defaults.headers.common["x-auth-token"] = token;
    const { data: jwt } = await axios.get(
      `${host}/api/auth/activation/${token}`
    );
    dispatch({ type: actionTypes.POST_USER_SUCCESS, payload: jwt });
    window.location.replace("/");
  } catch (error) {
    dispatch({
      type: actionTypes.POST_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const postUserImg = (formData) => async (dispatch) => {
  dispatch({ type: actionTypes.POST_USER_IMG_REQUEST });
  try {
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.post(`${host}/api/users/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        dispatch({
          type: actionTypes.POST_USER_IMG_PERCENTAGE,
          payload: parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total),
            10
          ),
        });
      },
    });
    dispatch({
      type: actionTypes.POST_USER_IMG_SUCCESS,
      payload: data,
    });
    // Clear percentage
    setTimeout(
      () =>
        dispatch({
          type: actionTypes.POST_USER_IMG_PERCENTAGE,
          payload: 0,
        }),
      5000
    );
    toast(`Your file ${data.fileName} has been uploaded to ${data.filePath}`);
  } catch (error) {
    dispatch({ type: actionTypes.POST_USER_IMG_PERCENTAGE, payload: 0 });
    dispatch({
      type: actionTypes.POST_USER_IMG_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const getUserDetails = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_USER_DETAILS_REQUEST });
  try {
    axios.defaults.headers.common["x-auth-token"] =
      localStorage.getItem("token");
    const { data } = await axios.get(`${host}/api/users/me`);
    dispatch({ type: actionTypes.GET_USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    toast.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    );
  }
};

export const getUserImgUrl = (userId) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_USER_IMG_REQUEST });
  try {
    const { data } = await axios.get(`${host}/api/users/image/${userId}`);
    dispatch({ type: actionTypes.GET_USER_IMG_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: actionTypes.GET_USER_IMG_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
