import React from "react";

function AboutSection({ web, textChange, imageChange, imageSubmit }) {
  return (
    <div className="admin-form tab-pane fade show" id="about">
      <form>
        <h4 className="mb-3">About Section</h4>
        <div className="form-group">
          <input
            name="title"
            onChange={textChange}
            value={web.about.title}
            type="text"
            className="form-control"
            placeholder="Title"
          />
        </div>
        <div className="form-group">
          <input
            name="subtitle"
            onChange={textChange}
            value={web.about.subtitle}
            type="text"
            className="form-control"
            placeholder="Subtitle"
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            name="description"
            value={web.about.description}
            rows="3"
            onChange={textChange}
            placeholder="Description"
          ></textarea>
        </div>
        <div className="form-group">
          <div className="input-group">
            <input
              className="form-control"
              type="file"
              id="formFile"
              onChange={imageChange}
            />
            <button
              className="btn btn-primary"
              type="submit"
              onClick={imageSubmit}
            >
              Upload
            </button>
          </div>
          <div className="progress">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              // style={{ width: `${blogImgPercentage}%` }}
            >
              {/* {blogImgPercentage}% */}
            </div>
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col-lg-6">
          <img className="img-fluid" src={web.about.image} alt="" />
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
