import React, { useState } from "react";
import "../css/adminForm.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createUser } from "../redux/actions/userActions";
import ShowPwsIcon from "./ShowPwsIcon";

function AdminRegisterForm({ changeForm }) {
  const dispatch = useDispatch();

  const [text, setText] = useState({});
  const [showPws, setShowPws] = useState(false);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value });
  };

  const toggleShowPws = () => {
    setShowPws(!showPws);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createUser(text));
  };

  return (
    <div className="admin-form">
      <h4>Admin Register</h4>
      <p>
        Authrization required before register an account. Required fields are
        marked *{" "}
      </p>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6 form-group">
            <input
              name="name"
              type="text"
              onChange={handleTextChange}
              value={text.name ? text.name : ""}
              className="form-control"
              placeholder="Your Name*"
            />
          </div>
          <div className="col-md-6 form-group">
            <input
              name="email"
              type="email"
              onChange={handleTextChange}
              value={text.email ? text.email : ""}
              className="form-control"
              placeholder="Your Email*"
            />
          </div>
        </div>
        <div className="row">
          <div className="col form-group password">
            <input
              name="password"
              type={`${showPws ? "text" : "password"}`}
              onChange={handleTextChange}
              value={text.password ? text.password : ""}
              className="form-control"
              placeholder="Your Password*"
            />
            <ShowPwsIcon toggleShowPws={toggleShowPws} showPws={showPws} />
          </div>
        </div>

        <button type="submit" className="btn btn-primary">
          Register
        </button>
      </form>
      <div className="row">
        <Link className="change-form-link" onClick={changeForm}>
          Admin Login <i className="bi bi-arrow-right"></i>
        </Link>
      </div>
    </div>
  );
}

export default AdminRegisterForm;
