import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import { getCurrentUser } from "../redux/reducers/userReducers";

function AdminUserProfile() {
  const user = getCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !user && navigate("/admin-entry");
  }, []);

  return (
    <>
      <Helmet>
        <title>DStudio Tech. | Admin | User Profile</title>
      </Helmet>
      <main id="main">
        <Breadcrumbs title="Admin User Profile" />
        <section className="inner-page">
          <div className="container">
            <p>This page is not yet developed</p>
          </div>
        </section>
      </main>
    </>
  );
}

export default AdminUserProfile;
