import React, { useContext } from 'react';
import '../css/topBar.css';
import { EnvContext } from '../App';

function TopBar() {
  const { scroll } = useContext(EnvContext);

  return (
    <section
      id="topbar"
      className={`topbar fixed-top d-flex align-items-center  ${
        scroll > 100 ? 'topbar-scrolled' : undefined
      }`}
    >
      <div className="container d-flex justify-content-center justify-content-md-between">
        <div className="contact-info d-flex align-items-center">
          <i className="bi bi-envelope d-flex align-items-center">
            <a target="_blank" href="mailto:info@dstutiotech.com">
              info@dstutiotech.com
            </a>
          </i>
          <i className="bi bi-phone d-flex align-items-center ms-4">
            <span>(+61)0404368885</span>
          </i>
        </div>
        <div className="social-links d-none d-md-flex align-items-center">
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCVSI9K36DvRWkqmH4XMZzOw"
            className="youtube"
          >
            <i className="bi bi-youtube"></i>
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/profile.php?id=100093911182490"
            className="facebook"
          >
            <i className="bi bi-facebook"></i>
          </a>
          <a
            target="_blank"
            href="https://www.google.com/search?q=DStudio+Technology&stick=H4sIAAAAAAAA_-NgU1I1qEg0SU1OMTY3ME0xNjROS0yxMqgwSbI0NLIwMjUxMTZPNjU2WMQq5BJcUpqSma8QkpqckZefk59eCQBzDfJhPgAAAA&hl=en&mat=CSqKnI91HrTvElYBeenfiLVnmnUeJyfw7Jl1kp6zYm2V0EMqZaBe3aysBxgYYH0sM7rCYRifq2QbbueZdhu7Z_a13CWboaVS1Ho4Hid8hGw4XgiSe5cf9fxuGD_QY8U9Dw&authuser=0&bshm=lbsc/1"
            className="google"
          >
            <i className="bi bi-google"></i>
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/dstudiotech618/"
            className="instagram"
          >
            <i className="bi bi-instagram"></i>
          </a>
        </div>
      </div>
    </section>
  );
}

export default TopBar;
