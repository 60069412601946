import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  postTrainingItem,
  getTrainingItemDetails,
  updateTrainingItem,
  deleteTrainingItem,
} from '../redux/actions/trainingItemActions';
import { getCurrentUser } from '../redux/reducers/userReducers';
import { postUserImg } from '../redux/actions/userActions';
import Breadcrumbs from '../components/Breadcrumbs';
import Preloader from '../components/Preloader';
import '../css/adminTrainingEdit.css';
import NotFoundPage from './NotFoundPage';

function AdminTrainingEdit() {
  const dispatch = useDispatch();
  const { id: trainingId } = useParams();
  const user = getCurrentUser();
  const navigate = useNavigate();

  const [training, setTraining] = useState({});
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [uploadedFile, setUploadedFile] = useState({});
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    !user && navigate('/admin-entry');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (trainingId === 'new') return;
    dispatch(getTrainingItemDetails(trainingId));
  }, [trainingId, dispatch]);

  const { loading, item, error } = useSelector(
    state => state.getTrainingItemDetails
  );

  const { uploadPercentage, postedFile } = useSelector(
    state => state.postUserImg
  );

  // set training item data
  useEffect(() => {
    if (trainingId === 'new') return;
    if (!loading && !error) {
      setTraining(item);
    }
  }, [item, trainingId, loading, error]);

  useEffect(() => {
    postedFile && setUploadedFile(postedFile);
  }, [postedFile]);

  // handle portfolio text change
  const handleTextChange = e => {
    const { name, value } = e.target;
    setTraining({ ...training, [name]: value });
  };

  // handle check box
  const handleCheckChange = () => {
    setTraining({ ...training, private: !training.private });
  };

  // handle delete training item
  const handleDeleteTraining = id => {
    if (trainingId === 'new') {
      toast.error('This training item has not been created yet');
      return;
    }
    dispatch(deleteTrainingItem(id));
  };

  // Image change and upload
  const onImageChange = e => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  // Image upload
  const handleImgSubmit = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    dispatch(postUserImg(formData));
  };

  // handle copy to clipboard
  const handleClipboardChange = text => {
    navigator.clipboard.writeText(text);
    setClicked(true);
  };

  // handle form submit
  const handleSubmit = e => {
    e.preventDefault();
    if (trainingId === 'new') {
      dispatch(postTrainingItem(training));
    } else {
      dispatch(updateTrainingItem(trainingId, training));
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        <NotFoundPage />
      ) : (
        <>
          <Helmet>
            <title>DStudio Tech. | Admin | Training Edit</title>
          </Helmet>
          <main id="main">
            <Breadcrumbs title="Edit Training" />
            <section className="admin-training-edit">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  >
                    <div className="admin-form">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-3">Edit Training Item</h4>
                        <i
                          className="bi bi-trash3"
                          onClick={() => {
                            handleDeleteTraining(trainingId);
                          }}
                        ></i>
                      </div>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            name="name"
                            onChange={handleTextChange}
                            value={training.name ? training.name : ''}
                            type="text"
                            className="form-control"
                            placeholder="Training Item Name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="title"
                            onChange={handleTextChange}
                            value={training.title ? training.title : ''}
                            type="text"
                            className="form-control"
                            placeholder="Training Item Title"
                          />
                        </div>
                        <div className="row d-flex align-items-center">
                          <div className="form-group col-md-8">
                            <select
                              name="category"
                              className="form-select"
                              value={training.category ? training.category : ''}
                              onChange={handleTextChange}
                            >
                              <option defaultValue>Choose Category</option>
                              <option value="javaScript">javaScript</option>
                              <option value="Python">Python</option>
                              <option value="HTML & CSS">HTML & CSS</option>
                              <option value="React.js">React.js</option>
                              <option value="React Native">React Native</option>
                              <option value="Node.js">Node.js</option>
                              <option value="SQL">SQL</option>
                              <option value="MongoDB">MongoDB</option>
                              <option value="php">php</option>
                              <option value="Data Analytics">
                                Data Analytics
                              </option>
                              <option value="Info System">Info System</option>
                              <option value="Technology">Technology</option>
                              <option value="AI">AI</option>
                              <option value="Others">Others</option>
                            </select>
                          </div>
                          <div className="form-group form-check form-switch col-md-4">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={training.private ? true : false}
                              onChange={handleCheckChange}
                            />
                            <label className="form-check-label ms-2">
                              {training.private ? 'Private' : 'Public'}
                            </label>
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            name="video"
                            onChange={handleTextChange}
                            value={training.video ? training.video : ''}
                            type="text"
                            className="form-control"
                            placeholder="Training Video"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="link"
                            onChange={handleTextChange}
                            value={training.link ? training.link : ''}
                            type="text"
                            className="form-control"
                            placeholder="Video Link"
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            name="description"
                            value={training.description}
                            rows="5"
                            onChange={handleTextChange}
                            placeholder="Training Description"
                          ></textarea>
                        </div>

                        <div className="form-group">
                          <h6>Upload Training Item Image</h6>
                          <div className="input-group">
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              onChange={onImageChange}
                            />
                            <button
                              className="btn btn-primary"
                              type="submit"
                              onClick={handleImgSubmit}
                            >
                              Upload
                            </button>
                          </div>
                          <div className="progress">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{ width: `${uploadPercentage}%` }}
                            >
                              {uploadPercentage}%
                            </div>
                          </div>
                        </div>
                        <div className="form-group clipboard">
                          <input
                            name="imageUrl"
                            value={
                              uploadedFile.filePath
                                ? uploadedFile.filePath
                                : 'Image Path'
                            }
                            type="text"
                            className="form-control"
                            placeholder="Image Path"
                            disabled
                          />
                          <i
                            className={`bi ${
                              clicked ? 'bi-clipboard-check' : 'bi-clipboard'
                            }`}
                            onClick={() =>
                              handleClipboardChange(
                                uploadedFile.filePath
                                  ? uploadedFile.filePath
                                  : 'Image Path'
                              )
                            }
                          ></i>
                        </div>
                        <div className="form-group">
                          <input
                            name="thumnail"
                            onChange={handleTextChange}
                            value={training.thumnail ? training.thumnail : ''}
                            type="text"
                            className="form-control"
                            placeholder="Training Item Thumbnail"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="publisher"
                            onChange={handleTextChange}
                            value={training.publisher ? training.publisher : ''}
                            type="text"
                            className="form-control"
                            placeholder="Training Item Publisher"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="avatar"
                            onChange={handleTextChange}
                            value={training.avatar ? training.avatar : ''}
                            type="text"
                            className="form-control"
                            placeholder="Publisher Avatar"
                          />
                        </div>
                        <div className="d-flex justify-content-center">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    className="col-lg-6"
                    data-aos="fade-left"
                    data-aos-delay="100"
                  >
                    <div className="admin-form">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-3">Your Training Item</h4>
                      </div>
                      <form>
                        <div className="form-group">
                          <input
                            name="name"
                            value={training.name ? training.name : ''}
                            type="text"
                            className="form-control"
                            placeholder="Training Item Name"
                            disabled
                          />
                        </div>
                        <div className="form-group">
                          <input
                            name="title"
                            value={training.title ? training.title : ''}
                            type="text"
                            className="form-control"
                            placeholder="Training Item Title"
                            disabled
                          />
                        </div>
                        <div className="row d-flex align-items-center">
                          <div className="form-group col-md-8">
                            <input
                              name="category"
                              value={training.category ? training.category : ''}
                              type="text"
                              className="form-control"
                              placeholder="Training Item Category"
                              disabled
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <p>{training.private ? 'Private' : 'Public'}</p>
                          </div>
                        </div>
                        {training.video && (
                          <div className="row">
                            <div className="form-group col-12">
                              <div className="ratio ratio-16x9">
                                <iframe
                                  src={training.video}
                                  allowFullScreen
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            name="description"
                            value={training.description}
                            rows="5"
                            placeholder="Training Description"
                            disabled
                          ></textarea>
                        </div>
                        <div className="row d-flex align-items-center">
                          <div className="form-group col-md-8">
                            <input
                              name="publisher"
                              value={
                                training.publisher ? training.publisher : ''
                              }
                              type="text"
                              className="form-control"
                              placeholder="Training Item Publisher"
                              disabled
                            />
                          </div>
                          {training.avatar && (
                            <div className="form-group col-md-4">
                              <img
                                className="avatar"
                                src={training.avatar}
                                alt="Avatar"
                              />
                            </div>
                          )}
                        </div>
                        {training.thumnail && (
                          <div className="form-group">
                            <h6>Training Item Thumbnail</h6>
                            <img
                              className="img-fluid"
                              src={training.thumnail}
                              alt="Thumbnail"
                            />
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default AdminTrainingEdit;
