import React, { useState } from "react";
import { Helmet } from "react-helmet";
import AdminLoginForm from "../components/AdminLoginForm";
import Breadcrumbs from "../components/Breadcrumbs";
import AdminRegisterForm from "../components/AdminRegisterForm";

function AdminEntryPage() {
  const [loginActive, setLoginActive] = useState(true);

  const handleChangeForm = () => {
    setLoginActive(!loginActive);
  };
  return (
    <>
      <Helmet>
        <title>DStudio Tech. | Admin Entry</title>
      </Helmet>
      <main id="main">
        <Breadcrumbs title="Admin Entry" />
        <section className="admin-entry">
          <div
            className="container d-flex justify-content-center align-items-center"
            data-aos="fade-up"
          >
            <div className="row">
              {loginActive ? (
                <div className="col">
                  <AdminLoginForm changeForm={handleChangeForm} />
                </div>
              ) : (
                <div className="col">
                  <AdminRegisterForm changeForm={handleChangeForm} />
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default AdminEntryPage;
