export const GET_ITEMS_REQUEST = "GET_ITEMS_REQUEST";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL";

export const GET_ITEM_DETAILS_REQUEST = "GET_ITEM_DETAILS_REQUEST";
export const GET_ITEM_DETAILS_SUCCESS = "GET_ITEM_DETAILS_SUCCESS";
export const GET_ITEM_DETAILS_FAIL = "GET_ITEM_DETAILS_FAIL";
export const GET_ITEM_DETAILS_RESET = "GET_ITEM_DETAILS_RESET";

export const POST_ITEM_REQUEST = "POST_ITEM_REQUEST";
export const POST_ITEM_SUCCESS = "POST_ITEM_SUCCESS";
export const POST_ITEM_FAIL = "POST_ITEM_FAIL";

export const PUT_ITEM_REQUEST = "PUT_ITEM_REQUEST";
export const PUT_ITEM_SUCCESS = "PUT_ITEM_SUCCESS";
export const PUT_ITEM_FAIL = "PUT_ITEM_FAIL";

export const DELETE_ITEM_REQUEST = "DELETE_ITEM_REQUEST";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAIL = "DELETE_ITEM_FAIL";
