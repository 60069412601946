import React, { useContext } from 'react';
import '../css/contact.css';
import { EnvContext } from '../App';
import ContactForm from './ContactForm';

function Contact() {
  const { sectionRefs: refs } = useContext(EnvContext);
  return (
    <section id="contact" className="contact" ref={refs.contact}>
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Contact</h2>
          <p>Contact Us</p>
        </header>

        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="row gy-4">
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p>
                    SE4, Level 7, 243 Edward St.,
                    <br />
                    Brisbane, QLD 4000
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p>
                    Mobile
                    <br />+ 61 0404 368 885
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>
                    info@dstudiotech.com
                    <br />
                    webmail.dstudio@gmail.com
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-clock"></i>
                  <h3>Working Hours</h3>
                  <p>
                    Monday - Friday
                    <br />
                    9:00AM - 05:00PM
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
