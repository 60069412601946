import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Breadcrumbs from "../components/Breadcrumbs";
import Portfolio from "../components/Portfolio";

function PortfolioPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>DStudio Tech. | Portfolio</title>
      </Helmet>
      <main id="main">
        <Breadcrumbs title={"Portfolio"} />
        <Portfolio />
      </main>
    </>
  );
}

export default PortfolioPage;
