import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCurrentUser } from '../redux/reducers/userReducers';
import {
  postBlogItem,
  getBlogItemDetails,
  updateBlogItem,
  deleteBlogItem,
} from '../redux/actions/blogItemActions';
import { postUserImg } from '../redux/actions/userActions';
import Breadcrumbs from '../components/Breadcrumbs';
import Preloader from '../components/Preloader';
import NotFoundPage from './NotFoundPage';
import '../css/adminBlogsEdit.css';

function AdminBlogsEdit() {
  const dispatch = useDispatch();
  const { id: blogId } = useParams();
  const user = getCurrentUser();
  const navigate = useNavigate();

  useEffect(() => {
    !user && navigate('/admin-entry');
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const newSectionItem = {
    title: '',
    image: '',
    quote: '',
    paragraph: '',
  };

  const newAuthor = {
    name: '',
    description: '',
    social: [],
    image: '',
  };

  const newBlog = {
    userId: user && user._id,
    blogTitle: '',
    blogImage: '',
    category: '',
    tags: [],
    section: [],
    private: false,
    author: newAuthor,
  };

  // set up
  const [text, setText] = useState(newBlog);
  const [tag, setTag] = useState('');
  const [author, setAuthor] = useState(text.author);
  const [tagList, setTagList] = useState(text.tags);
  const [privacy, setPrivacy] = useState(text.private);
  const [sectionItem, setSectionItem] = useState(newSectionItem);
  const [sectionItemList, setSectionItemList] = useState(text.section);
  const [imgTarget, setImgTarget] = useState('');
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('');
  const [blogImgPercentage, setBlogImgPercentage] = useState(0);
  const [sectionImgPercentage, setSectionImgPercentage] = useState(0);

  useEffect(() => {
    if (blogId === 'new') return;
    dispatch(getBlogItemDetails(blogId));
  }, [blogId, dispatch]);

  const { loading, item, error } = useSelector(
    state => state.getBlogItemDetails
  );

  const { uploadPercentage, postedFile } = useSelector(
    state => state.postUserImg
  );

  useEffect(() => {
    if (blogId === 'new') return;
    if (!loading && !error) {
      setText(item);
      setSectionItemList(item.section);
      setAuthor(item.author);
      setTagList(item.tags);
      setPrivacy(item.private);
    }
  }, [item, blogId, loading]);

  // change input text
  const handleTextChange = e => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value });
  };

  // change section state on initial creation
  const handleSectionChange = e => {
    const { name, value } = e.target;
    setSectionItem({ ...sectionItem, [name]: value });
  };

  // update sections state for existing blogs
  const handleSectionMapChange = (e, id) => {
    const { name, value } = e.target;
    const update = sectionItemList.map((part, index) => {
      if (index === id) {
        part = { ...part, [name]: value };
      }
      return part;
    });
    setSectionItemList(update);
    setText({ ...text, section: update });
  };

  // update image state for existing blogs
  const handleUpdateSectionImages = id => {
    const targetSection = sectionItemList[id];
    if (postedFile) {
      sectionItemList[id] = { ...targetSection, image: postedFile.filePath };
      setText({ ...text, section: sectionItemList });
    } else {
      toast.error('No Image Uploaded');
    }
  };

  // add a new blog section
  const handleAddNewSection = () => {
    if (sectionItem.paragraph === '') {
      toast.error('Empty section is not allowed');
    } else {
      setSectionItemList([...sectionItemList, sectionItem]);
      setSectionItem(newSectionItem);
      setText({ ...text, section: [...sectionItemList, sectionItem] });
    }
  };

  // delete a blog section
  const handleDeleteSection = id => {
    const filter = sectionItemList.filter((part, index) => index !== id);
    setSectionItemList(filter);
    setText({ ...text, section: filter });
  };

  // Author
  const handleAuthorChange = e => {
    const { name, value } = e.target;
    setText({ ...text, author: { ...text.author, [name]: value } });
  };

  // Privacy
  const handlePrivacyChange = e => {
    setPrivacy(!privacy);
    setText({ ...text, private: !privacy });
  };

  // Categories
  const handleSelectChange = e => {
    const { value } = e.target;
    setText({ ...text, category: value });
  };

  // Tags
  const handleTagChange = e => {
    setTag(e.target.value);
  };

  const handleOnKeyUp = e => {
    if (e.keyCode === 13) {
      if (tag.trim().length === 0) {
        toast.info('No tag entered');
        return;
      }
      setTagList([...tagList, tag.trim()]);
      setTag('');
      setText({ ...text, tags: [...tagList, tag.trim()] });
    }
  };

  const handleDeleteTag = targetTag => {
    const updatedTags = tagList.filter(tag => tag !== targetTag);
    setTagList(updatedTags);
    setText({ ...text, tags: updatedTags });
  };

  // Image change and upload
  const onImageChange = e => {
    setImgTarget(e.target.name);
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  // Image upload
  const handleImgSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    await dispatch(postUserImg(formData));
  };

  // update image url state, and set percentage bar state
  useEffect(() => {
    if (imgTarget === 'blogImage') {
      postedFile && setText({ ...text, blogImage: postedFile.filePath });
      setBlogImgPercentage(uploadPercentage);
    } else if (imgTarget === 'sectionImage') {
      setSectionImgPercentage(uploadPercentage);
    } else {
      return;
    }
  }, [postedFile, uploadPercentage, imgTarget]);

  // Delete a blog
  const handleDeleteBlog = async id => {
    if (blogId === 'new') {
      toast.error('Empty blog cannot be deleted');
      return;
    }
    await dispatch(deleteBlogItem(id));
  };
  // Form submit
  const handleSubmit = async e => {
    e.preventDefault();
    if (blogId === 'new') {
      await dispatch(postBlogItem(text));
    } else {
      await dispatch(updateBlogItem(blogId, text));
    }
  };

  return (
    <>
      {loading ? (
        <Preloader />
      ) : error ? (
        <NotFoundPage />
      ) : (
        <>
          <Helmet>
            <title>DStudio Tech. | Admin | Blogs Edit</title>
          </Helmet>
          <main id="main">
            <Breadcrumbs title="Edit Blog" />
            <section className="admin-blogs-mgmt">
              <div className="container">
                <div className="row">
                  {text && (
                    <div
                      className="col-lg-6"
                      data-aos="fade-right"
                      data-aos-delay="100"
                    >
                      <div className="admin-form">
                        <div className="d-flex justify-content-between align-items-center">
                          <h4 className="mb-3">Edit Blog</h4>
                          <i
                            className="bi bi-trash3"
                            onClick={() => {
                              handleDeleteBlog(blogId);
                            }}
                          ></i>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="form-group">
                              <input
                                name="blogTitle"
                                onChange={handleTextChange}
                                value={text.blogTitle ? text.blogTitle : ''}
                                type="text"
                                className="form-control"
                                placeholder="Blog Title"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group">
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  type="file"
                                  id="formFile"
                                  name="blogImage"
                                  onChange={onImageChange}
                                />
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                  onClick={handleImgSubmit}
                                >
                                  Upload
                                </button>
                              </div>
                              <div className="progress">
                                <div
                                  className="progress-bar bg-success"
                                  role="progressbar"
                                  style={{ width: `${blogImgPercentage}%` }}
                                >
                                  {blogImgPercentage}%
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row d-flex align-items-center">
                            <div className="form-group col-md-6">
                              <select
                                className="form-select"
                                value={text.category ? text.category : ''}
                                onChange={handleSelectChange}
                              >
                                <option defaultValue>Choose Category</option>
                                <option value="Web Design">Web Design</option>
                                <option value="Web Development">
                                  Web Development
                                </option>
                                <option value="App Development">
                                  App Development
                                </option>
                                <option value="Graphic Design">
                                  Graphic Design
                                </option>
                                <option value="Digital Marketing">
                                  Digital Marketing
                                </option>
                                <option value="Database">Database</option>
                                <option value="Data Analytics">
                                  Data Analytics
                                </option>
                                <option value="IT Skills">IT Skills</option>
                                <option value="Technology">Technology</option>
                                <option value="Info System">Info System</option>
                                <option value="AI">AI</option>
                                <option value="Others">Others</option>
                              </select>
                            </div>

                            <div className="form-group form-check form-switch col-md-6">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={text.private ? true : false}
                                onChange={handlePrivacyChange}
                              />
                              <label className="form-check-label ms-2">
                                {text.private ? 'Private' : 'Public'}
                              </label>
                            </div>
                          </div>
                          <div className="row">
                            {text.author && (
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  name="name"
                                  value={text.author.name}
                                  onChange={handleAuthorChange}
                                  type="text"
                                  placeholder="Author Name"
                                />
                              </div>
                            )}
                            {text.author && (
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  name="description"
                                  value={text.author.description}
                                  rows="3"
                                  onChange={handleAuthorChange}
                                  placeholder="Author Description"
                                ></textarea>
                              </div>
                            )}
                          </div>
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              name="tag"
                              rows="2"
                              value={tag}
                              onChange={handleTagChange}
                              onKeyUp={handleOnKeyUp}
                              placeholder="Tags (Press enter after each tag)"
                            ></textarea>
                          </div>

                          <div className="form-group d-flex justify-content-between">
                            <h5>Add Blog Section</h5>
                            <i
                              className="bi bi-plus-circle"
                              onClick={handleAddNewSection}
                            ></i>
                          </div>

                          <div className="row">
                            <div className="form-group">
                              <input
                                name="title"
                                type="text"
                                value={sectionItem.title}
                                onChange={handleSectionChange}
                                className="form-control"
                                placeholder="Section Title"
                              />
                            </div>
                            <div className="form-group">
                              <input
                                name="quote"
                                type="text"
                                value={sectionItem.quote}
                                onChange={handleSectionChange}
                                className="form-control"
                                placeholder="Quote"
                              />
                            </div>
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="paragraph"
                                value={sectionItem.paragraph}
                                onChange={handleSectionChange}
                                rows="6"
                                placeholder="Paragraph*"
                              ></textarea>
                            </div>
                          </div>

                          {text.section &&
                            text.section.map((part, index) => (
                              <div className="row" key={index}>
                                <div className="d-flex justify-content-between">
                                  <h5>Section {index + 1}</h5>
                                  <div className="section-icons">
                                    <i
                                      className="bi bi-card-image"
                                      onClick={() =>
                                        handleUpdateSectionImages(index)
                                      }
                                    ></i>
                                    <i
                                      className="bi bi-trash3"
                                      onClick={() => {
                                        handleDeleteSection(index);
                                      }}
                                    ></i>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <input
                                    name="title"
                                    type="text"
                                    value={text.section[index].title}
                                    onChange={e =>
                                      handleSectionMapChange(e, index)
                                    }
                                    className="form-control"
                                    placeholder="Section Title"
                                  />
                                </div>

                                <div className="form-group">
                                  <div className="input-group">
                                    <input
                                      className="form-control"
                                      type="file"
                                      id="formFile"
                                      name="sectionImage"
                                      onChange={onImageChange}
                                    />
                                    <button
                                      className="btn btn-primary"
                                      type="submit"
                                      onClick={handleImgSubmit}
                                    >
                                      Upload
                                    </button>
                                  </div>
                                  <div className="progress">
                                    <div
                                      className="progress-bar bg-success"
                                      role="progressbar"
                                      style={{
                                        width: `${sectionImgPercentage}%`,
                                      }}
                                    >
                                      {sectionImgPercentage}%
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group">
                                  <input
                                    name="quote"
                                    type="text"
                                    value={text.section[index].quote}
                                    onChange={e =>
                                      handleSectionMapChange(e, index)
                                    }
                                    className="form-control"
                                    placeholder="Quote"
                                  />
                                </div>
                                <div className="form-group">
                                  <textarea
                                    className="form-control"
                                    name="paragraph"
                                    value={text.section[index].paragraph}
                                    onChange={e =>
                                      handleSectionMapChange(e, index)
                                    }
                                    rows="6"
                                    placeholder="Paragraph*"
                                  ></textarea>
                                </div>
                              </div>
                            ))}

                          <div className="d-flex justify-content-center">
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                  {/* Presentation of blog article */}
                  {text && (
                    <div
                      className="col-lg-6"
                      data-aos="fade-left"
                      data-aos-delay="100"
                    >
                      <div className="admin-form">
                        <h4 className="mb-3">Blog Article</h4>
                        <form>
                          <div className="row">
                            <div className="form-group">
                              <input
                                name="blogTitle"
                                value={text.blogTitle ? text.blogTitle : ''}
                                type="text"
                                className="form-control"
                                placeholder="Blog Title"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="form-group">
                              <input
                                name="blogImage"
                                value={
                                  text.blogImage ? text.blogImage : filename
                                }
                                type="text"
                                className="form-control"
                                placeholder="Blog Image"
                                disabled
                              />
                            </div>
                            {text.blogImage && (
                              <div className="form-group">
                                <img
                                  src={text.blogImage ? text.blogImage : ''}
                                  alt="Blog Image"
                                  className="img-fluid"
                                />
                              </div>
                            )}
                          </div>

                          <div className="row d-flex align-items-center">
                            <div className="form-group col-md-6">
                              <input
                                name="category"
                                value={text.category ? text.category : ''}
                                type="text"
                                className="form-control"
                                placeholder="Category"
                                disabled
                              />
                            </div>

                            <div className="form-group form-check form-switch col-md-6">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={text.private ? true : false}
                                disabled
                              />
                              <label className="form-check-label ms-2">
                                {text.private ? 'Private' : 'Public'}
                              </label>
                            </div>
                          </div>
                          {text.author && (
                            <div className="row">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  name="name"
                                  value={text.author.name}
                                  onChange={handleAuthorChange}
                                  type="text"
                                  placeholder="Author Name"
                                  disabled
                                />
                              </div>
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  name="description"
                                  value={text.author.description}
                                  rows="3"
                                  onChange={handleAuthorChange}
                                  placeholder="Author Description"
                                  disabled
                                ></textarea>
                              </div>
                            </div>
                          )}

                          <div className="form-group">
                            <label className="form-label">Tags:</label>
                            <ul className="ms-2 tag-list d-inline-flex flex-wrap justify-content-start">
                              {text.tags &&
                                text.tags.map((tag, index) => (
                                  <li className="tag-icon mt-1" key={index}>
                                    {tag}{' '}
                                    <i
                                      onClick={() => handleDeleteTag(tag)}
                                      className="bi bi-x"
                                    ></i>
                                  </li>
                                ))}
                            </ul>
                          </div>

                          <div className="form-group d-flex justify-content-between">
                            <h5>Blog Sections</h5>
                          </div>
                          {text.section &&
                            text.section.map((part, index) => (
                              <div className="row" key={index}>
                                <div className="col-lg-12">
                                  <h5>{part.title}</h5>
                                  {part.image && (
                                    <img
                                      className="img-fluid"
                                      src={part.image}
                                      alt="Blog Section Image"
                                    />
                                  )}
                                  <p className="mt-2">{part.quote}</p>
                                  <p>{part.paragraph}</p>
                                </div>
                              </div>
                            ))}
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </main>
        </>
      )}
    </>
  );
}

export default AdminBlogsEdit;
